/*=========== Pages Style ==========*/

/*================= Mail =================*/

.slick-prev:before,
.slick-next:before {
   font-size: 35px;
  color: #008C95; 
  
}
.slick-prev, .slick-next {
   width: 35px;
    height: 35px;
}
.filter-heading {
   font-size: 18px;
}
.slick-list {
   margin-left: 22px;
   margin-right: 20px;
}

.rct-mail-wrapper,
.todo-wrapper {
   header {
      background-color: $white;
      box-shadow: none;
      border-top: 1px solid $border-color;
   }
   .list-wrap {
      margin: -24px;
      .top-head {
         border-bottom: 1px solid $border-color;
         border-top: 1px solid $border-color;
         padding: 0.625rem 1rem;
      }
      ul {
         .list-item {
            padding: 1rem;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            background-color: $white;
            &:not(:last-child) {
               border-bottom: 1px solid $border-color;
            }
            &:hover {
               background-color: $aqua;
            }
         }
      }
   }
}

//Sidebar
.mail-sidebar-wrap,
.todo-sidebar-wrap,
.chat-sidebar {
   overflow: hidden;
   .activity-history-list-sidebar {
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
      width: '100%';
      height: 612px;
   }
   .activity-history-list-sidebar-mobile-view {
      position: relative;
      overflow-y: scroll;
      overflow-x: hidden;
      width: '100%';
      height: calc(100vh - 60px);
   }
   .user-wrap {
      background: #fff;
      background-size: cover;
      background-position: center center;
      height: 150px;
      .media {
         width: 100%;
         padding: 1rem;
         background-color: rgb(235, 242, 245);
      }
   }
   .sidebar-filters-wrap {
      padding: 0 1.25rem 1.25rem;
      .filters {
         padding: 0;
         margin: 0;
         >div {
				color:$gray-900;
            i {
               font-size: 1.25rem;
            }
            .filter-title {
               font-size: 0.875rem;
            }
         }
      }
	}
	.sidebar-title {
		color:$gray-900;
	}
}

//Mail Detail Page
.email-detail-page-warrper {
   .mail-detail {
      padding: 1rem 1.5rem 3rem 6rem;
      border-bottom: 1px solid $border-color;
   }
}

.mail-reply-wrap,
.task-foot {
   textarea {
      max-height: 3rem;
      line-height: 2;
   }
}

/*================ Todo List ================*/

.todo-wrapper {
   @media(max-width:959px){
      header{
         margin-left:0 !important;
      }
   }
   .list-wrap {
      ul li {
         >.media {
            >label {
               margin: 0 1rem 0 0;
            }
            .media-body {
               span {
                  margin: 0 0.25rem;
               }
            }
         }
      }
      .task-head {
         padding: 0.625rem 1rem;
      }
   }
   .task-detail {
      .task-detail-content {
         .task-content {
            .task-box {
               position: relative;
               border: 1px solid $border-color;
               padding: 0.625rem 1.875rem 0.625rem 0.625rem
            }
            .task-btn {
               background-color: $white;
               border-radius: 100%;
               box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
               position: absolute;
               top: -15px;
               right: -5px;
               width: 35px;
               height: 35px;
               line-height: 35px;
               font-size: 1rem;
               span {
                  color: $primary !important;
               }
            }
         }
      }
      .task-comment {
         ul li {
            margin-bottom: 1rem;
         }
      }
   }
}

/*================== Chat ===================*/

.chat-wrapper {
   .chat-main-body {
      .chat-head {
         padding: 0.625rem 1.5rem;
         @media(max-width:450px){
            padding:0.75rem;
            .video-icon{
               display: none;
            }
         }
         box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.09);
         button {
            height: 2.25rem;
            width: 2.25rem;
            font-size: 1.25rem;
            margin: 0 0.25rem;
         }
      }
      .chat-body {
         .chat-bubble {
            max-width: 600px;
            padding: 0.625rem;
            box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
            display: flex;
            align-items: center;
            &.even {
               border-radius: 20px 20px 20px 0;
            }
            &.odd {
               border-radius: 20px 20px 0px 20px;
            }
         }
      }
      .chat-footer {
         .msg-input,
         .submit-btn {
            border-radius: 100px !important;
         }
      }
   }
   .chat-content {
      position: relative;
      height: calc(100vh - 186px);
      .chat-box-main {
         position: absolute;
         top: 0;
         bottom: 10rem;
         left: 0;
         right: 0;
         display: flex;
         justify-content: center;
         align-items: center;
      }
   }
}

.chat-sidebar {
   .user-wrap {
      height: auto;
   }
   .search-wrapper {
      .search-icon {
         position: absolute;
         right: 1rem;
         top: 0.9rem;
      }
   }
   .chat-list {
      ul {
         .user-list-item {
				color:$gray-900;
            cursor: pointer;
            background-color: #FFFFFF;
            border-radius: 5px;
            box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .08);
            padding: 1rem;
            transition: all 0.3s ease-in-out;
            .media-left {
               .badge {
                  position: absolute;
                  bottom: 0px;
               }
            }
            .msg-count {
               .badge {
                  height: 1.5rem;
                  width: 1.5rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0;
               }
            }
            &.item-active {
               background-color: rgb(214,234,243);
               color: #464D69;
               cursor: pointer;
               border-radius: 5px;
               box-shadow: 0 1px 15px 1px rgb(252, 252, 253);
               padding: 1rem;
               transition: all 0.3s ease-in-out;
               
            }
            &:hover {
               &:not(.item-active) {
                  background-color: #dae3e7;
               }
            }
         }
      }
   }
}

.activity-history-container-wrapper {
   .chat-content {
      height: auto !important; 
   }
}

/*================ Gallery =================*/

figure.img-wrapper {
   position: relative;
   overflow: hidden;
   text-align: center;
   -webkit-perspective: 50em;
   perspective: 50em;
   * {
      -webkit-box-sizing: padding-box;
      box-sizing: padding-box;
      -webkit-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
   }
   img {
      max-width: 100%;
      vertical-align: top;
   }
   figcaption {
      top: 50%;
      left: 20px;
      right: 20px;
      position: absolute;
      opacity: 0;
      z-index: 1;
   }
   h2,
   h4 {
      margin: 0;
   }
   h2 {
      font-weight: 600;
   }
   h4 {
      text-transform: uppercase;
   }
   &:after {
      background-color: $white;
      position: absolute;
      content: "";
      display: block;
      top: 20px;
      left: 20px;
      right: 20px;
      bottom: 20px;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      -webkit-transform: rotateX(-90deg);
      transform: rotateX(-90deg);
      -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      opacity: 0;
   }
   a {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      z-index: 1;
   }
   &:hover figcaption,
   &.hover figcaption {
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      opacity: 1;
      -webkit-transition-delay: 0.2s;
      transition-delay: 0.2s;
   }
   &:hover:after,
   &.hover:after {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      opacity: 0.9;
   }
}

/*======= Pricing Scss =======*/

.rct-tooltip {
   width: 250px;
}

.pricing-wrapper {
   button {
      text-transform: capitalize;
   }
   .switch {
      vertical-align: middle;
      margin: 0 1.25rem; //0 20px;
   }
}

.price-list {
   margin-bottom: 6.25rem;
   .rct-block {
      padding: 3.125rem;
      p {
         margin-bottom: 1.25rem; //20px;
      }
   }
}

.pricing-title {
   font-size: 1.875rem; //30px;
   margin-bottom: 1.25rem; //20px
   text-transform: capitalize;
}

.amount-title {
   font-weight: 900;
   font-size: 2.25rem; //36px;
}

.fixed-pricing {
   background-color: $block-bg;
   border-radius: $block-border-radius;
   box-shadow: $block-shadow;
   transition: $block-transition;
   margin-bottom: $block-margin-bottom;
   position: relative;
   .pricing-box {
      @include border(1px solid,
      $input-border-color,
      right);
   }
}

.pricing-body,
.pricing-head {
   padding: 1.875rem 3.125rem; //30px 50px;
}

.plan-info {
   background-color: $gray-400;
   padding: 0.625rem 3.125rem; //10px 50px;
}

.plan-info-listing {
   @extend .price-detail;
   li {
      i {
         margin-right: 0.9375rem;
      }
   }
}

.price-detail {
   margin-bottom: 2.188rem; //35px;
   li {
      margin-bottom: 0.625rem; //10px;
      font-size: 0.875rem; //14px;
   }
}

.testimonial-wrapper {
   .media-body {
      p {
         font-style: italic;
         font-size: 1.125rem; //18px;
         margin-bottom: 1.25rem; //20px;
         line-height: 30px;
      }
   }
   .user-meta {
      span {
         color: $gray-700;
      }
   }
}

.faq-wrapper {
   .card-title {
      margin-bottom: 1.25rem; //20px
      font-size: 1rem; //16px;
   }
   .card-text {
      font-size: 0.875rem; //14px;
      color: $gray-700;
   }
}

.testimonial-slider {
   .slick-dots {
      text-align: left;
      bottom: -50px;
      left: 185px;
   }
}

/*======== About Scss =========*/

.section-title::after {
   content: "";
   width: 70px;
   height: 1px;
   display: inline-block;
   border: 2px solid $pink;
   position: absolute;
   left: 0;
   right: 0;
   margin: 6px auto;
   border-radius: 30px;
   transition: all .3s ease-in-out 0s;
}

.card-content {
   p {
      color: $gray-600;
      text-align: justify;
   }
}

.about-detail {
   .card-image {
      img {
         width: 100%;
      }
   }
}

/*======== Calender Scss =======*/

.calendar-wrapper {
   .rbc-calendar {
      display: block;
	}
	.rbc-time-slot{
		background-color:transparent !important;
	}
}

.rbc-month-row {
   min-height: 150px;
}

.rbc-event {
   background-color: $blue;
   padding: 10px;
   border-radius: 5px;
   border: 1px solid $blue !important;
}

.rbc-toolbar button.rbc-active,
.rbc-toolbar button:hover {
   background-color: $blue;
   color: $white;
}

@media (max-width: 700px) and (min-width: 320px) {
   .rbc-toolbar {
      font-size: 12px;
      flex-direction: column;
      align-items: start;
      span {
         margin: 5px 0;
      }
   }
   .rbc-month-row {
      min-height: 70px;
   }
}
.profile-dropdown-icon {
   width: 10px;
   height: 35px;
   display: inline-block;
   padding-top:6px;
}
/*========= Activities List View =====*/

.right-panel-scroll {
   overflow-y: scroll;
   height: calc(100vh - 314px);
}

.actvity-routine {
   width: 60%;
}
.actvity-routine-simple {
   width: 100%;
}

.alert-description {
   width: 90%;
   .alert-desc-truncate{
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.2;
      font-size: 14px;
      white-space: nowrap;
   }
}

.actvity-routine-date {
   width: 20%;
}
.activity-button {
   justify-content: center;
}

@media(max-width: 767px) {
   .actvity-routine {
      width: 100%;
      text-align: left;
      margin-left: 5px;
   }
   .actvity-routine-date {
      width: 40%;
   }
   .activity-button {
      justify-content: left;
   }
   .right-panel-scroll {
      overflow-y: scroll;
      height: calc(100vh - 200px);
   }
}

/*========= Terms Condition =====*/

.terms-wrapper {
   overflow-y: auto;
   height: 100%;
}

.terms-conditions-rules {
   p {
      margin-bottom: 1.25rem;
   }
   .sub-order {
      padding-left: 3.75rem; //60px;
   }
   ol {
      padding-left: 2.5rem; //40px;
      li {
         margin-bottom: 0.9375rem;
      }
   }
   .heading {
      font-size: 1.5rem; //24px;
      margin-bottom: 1.563rem; //25px;
   }
   .nest-heading {
      font-weight: 700;
      line-height: 30px;
      margin-bottom: 0.625rem;
      padding-left: 1.875rem; //30px;
   }
   p,
   ol li,
   .nest-heading {
      font-size: 0.875rem;
   }
}

/*========= Feedback ============*/

.feedback-wrapper {
   .heading {
      font-size: 1.5rem; //24px;
      margin-bottom: 1.563rem; //25px;
      color: $gray-700;
   }
}

.like-box {
   @include border(2px solid,
   $gray-700,
   top bottom left right);
   width: 100px;
   height: 100px;
   text-align: center;
   margin-right: 3.25rem;
   padding: 0.625rem;
}

.comment-sec {
   .media {
      margin-bottom: 1.875rem; //30px;
   }
   .comment-box {
      background-color: $gray-200;
      padding: 0.625rem;
      p {
         font-size: 0.875rem; //14px;
      }
   }
}

.rct-tabs {
   header {
      background-color: $white;
      box-shadow: none;
      color: $gray-700;
      @include border(1px solid,
      $border-color,
      bottom);
      span {
         font-size: 0.875rem;
      }
   }
   ul {
      li {
         margin: 0 -24px !important;
         padding: 0.9375rem 1.25rem;
         @include border(1px solid,
         $border-color,
         bottom);
      }
   }
   .form-group {
      .col-form-label {
         font-size: 1rem;
      }
   }
   [role="tablist"] {
      button {
         color: $gray-900;
      }
   }
}

.social-action {
   @extend .list-action;
   a,button {
      padding: 5px;
   }
}

.list-action {
   a,button {
      color: $gray-700;
      font-size: 1.125rem; //18px;
      &:hover {
         color: $blue;
      }
   }
   a ,button{
      margin-right: 0.625rem; //10px;
      &:last-child {
         margin-right: 0;
      }
   }
}

.feed-content {
   @include border(3px solid,
   $primary,
   left);
   a {
      margin-bottom: 0;
      color: $gray-800;
      padding: 0.625rem;
      font-size: 1.125rem; //18px;
      line-height: 30px;
      &:hover {
         color: $primary;
      }
   }
}

.search-filter {
   padding: 1.25rem; //20px;
   .form-group {
      margin-right: 0.9375rem; //15px;
      display: inline-block;
      vertical-align: top;
   }
}

/*========= Report =============*/

.check-circle {
   background-color: $block-bg;
   border-radius: 100%;
   border: 1px solid $success;
   height: 35px;
   width: 35px;
   line-height: 35px;
   text-align: center;
   margin-right: 1.25rem;
}

.report-list {
   li {
      @include border(1px solid,
      $input-border-color,
      bottom);
      padding: 1.25rem;
      &:last-child {
         border-bottom: none;
      }
      h2 {
         color: $gray-900;
         font-size: 2rem; //32px;
      }
   }
}

.report-status {
   background-color: $block-bg;
   @include border(1px solid,
   $input-border-color,
   top left bottom right);
   border-radius: $block-border-radius;
   >ul {
      @include border(1px solid,
      $input-border-color,
      bottom top);
      >li {
         @include border(1px solid,
         $input-border-color,
         right);
         margin: 0 !important;
         padding: 1.875rem; //30px;
         &:last-child {
            border-right: none;
         }
         h4 {
            color: $gray-600;
         }
         .title {
            color: $gray-900;
         }
      }
   }
}

.top-filter {
   .form-group {
      float: left;
      margin-right: 0.9375rem;
   }
}
 
.badge-extension {
   position: absolute;
   bottom: 0px;
   right: 0px;
   width: 2.5rem;
   height: 2.5rem;
   display: flex;
   align-items: center;
   justify-content: center;
}
.badge-extension-mute {
   position: absolute;
   top: -5px;
   right: 0px;
   // left:75%;
   // width: 22px;
   // height:22px;
   display: flex;
   align-items: center;
   justify-content: center;
   color: "#4D4E4F";
}

.list-badge-extension-mute {
    align-items: center;
    position: absolute;
    box-sizing: border-box;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    min-width: 20px;
    line-height: 1;
    padding: 0 6px;
    height: 20px;
    border-radius: 10px;
    z-index: 1;
    left: 40px;
}
.badge-extensionactivity {
   position: absolute;
   bottom: 0px;
   right: 0px;
   width: auto;
   height: 17px;
   padding: auto;
   display: flex;
   align-items: center;
   justify-content: center;
}

/*========= Faq(s) =============*/

.faq-page-wrapper {
   .panel-heading {
      @extend .rct-block-title;
      border-bottom: none;
      >div {
         margin: 0;
      }
      h4 {
         color: $primary;
         padding: 0;
		}
	}
	p{
		color: $gray-900;
	}
}
.messages-wrapper{
   .media.pull-left{
      img{
         width:50px;
      }
      .media-body{
         width:calc(100% - 50px);
      }
   }
}
.activity-scroll{
   position: relative;
   overflow: hidden;
   width: 100%;
   height: calc(100vh - 0px);
   overflow-y: scroll;
}
/*======== Responsive =========*/

@media (max-width: 1767px) and (min-width: 320px) {
   .about-detail {
      .p-70 {
         padding: 1.25rem 1.25rem !important;
      }
   }
   .about-content {
      .title {
         line-height: 1.2;
      }
   }
}

@media (max-width: 1500px) and (min-width: 320px) {
   .about-content {
      .title {
         font-size: 1.7rem;
      }
      .mb-50 {
         margin-bottom: 15px !important;
      }
   }
}

@media (max-width: 1400px) and (min-width: 320px) {
   .pricing-body,
   .pricing-head {
      padding: 1.875rem 1.125rem;
   }
   .price-list .rct-block {
      padding: 1.125rem;
   }
   .content-wrapper .title {
      line-height: 1.3;
   }
   .media-full {
      img {
         width: 200px;
      }
   }
}

.left-margin {
   margin-left: 0px;
}

@media (max-width: 1700px) and (min-width: 768px) {
   .left-margin {
      margin-left: 0px;
   }

}

@media (max-width: 767px) and (min-width: 320px) {
   .left-margin {
      margin-left: 14px;
   }

}

@media (min-width: 768px) {
   .img-fluid-extension {
      width: 100%;
      height: 256px;
   }

}

@media (max-width: 767px) and (min-width: 320px) {
   .img-fluid-extension {
      width: 100%;
      height: 230px;
   }
   .chat-sidebar {
      .search-wrapper {
         .search-icon {
            position: absolute;
            right: 1rem;
            top: 0.5rem;
         }
      }
   }

}

@media (max-width: 1700px) and (min-width: 1024px) {
   .userProfile-wrapper {
      .profile-top {
         .profile-content {
            top: 0px !important;
            left: 20px !important;
         }
      }
   }
}

@media (max-width: 1023px) and (min-width: 768px) {
   .userProfile-wrapper {
      .profile-top {
         .profile-content {
            top: 0px !important;
            left: 16px !important;
         }
      }
   }
}

@media (max-width: 1270px) {
   .about-content {
      .title {
         font-size: 1.375rem; //22px;
      }
   }
}

@media (max-width: 765px) and (min-width: 320px) {
   .profile-content {
      img {
         width: 90px;
         height: 90px;
      }
      .media-body.pt-25 {
         padding-top: 5px !important;
      }
   }
}

@media (max-width: 720px) and (min-width: 320px) {
   .report-status>ul>li {
      padding: .8rem;
      h2 {
         font-size: 2rem;
      }
   }
}

@media (max-width: 700px) and (min-width: 320px) {
   .testimonial-wrapper {
      &.mb-50 {
         margin-bottom: 1.875rem;
      }
      .media {
         display: inline-block;
         text-align: center;
         img {
            display: inline-block !important;
            margin: 0 auto 0.9375rem;
            margin-right: 0 !important;
         }
      }
   }
   .feedback-wrapper {
      .rct-tabs {
         ul {
            li {
               display: inline-block !important;
               .list-action {
                  text-align: center;
               }
            }
         }
         .media {
            display: inline-block;
            text-align: center;
            img {
               margin-right: 0 !important;
               margin-bottom: 0.9375rem;
            }
         }
      }
   }
}

@media (max-width: 560px) and (min-width: 320px) {
   .report-status {
      >ul {
         >li {
            width: 100%;
            @include border(1px solid,
            $input-border-color,
            bottom);
            border-right: none;
            &:last-child {
               border-bottom: none;
            }
         }
      }
   }
}

@media (max-width: 575px) and (min-width: 320px) {
   .report-status {
      .top-filter {
         .w-20 {
            width: 35% !important;
         }
      }
   }
   .feedback-wrapper {
      .comment-sec,
      form {
         width: 100% !important;
      }
      .ml-70 {
         margin-left: 0 !important;
      }
   }
   .like-box {
      width: 40px;
      height: 40px;
      margin: 1.25rem 1.25rem 1.25rem 0;
      h2,
      span {
         display: none;
      }
   }
   .comment-sec {
      .media,
      .media>div {
         display: inline-block;
         text-align: center;
         img {
            margin-bottom: 0.9375rem;
         }
      }
   }
   .search-filter {
      .w-40 {
         width: 50% !important;
      }
   }
   .userProfile-wrapper {
      .w-50,
      .w-75 {
         width: 100% !important;
      }
   }
   .profile-content {
      img {
         width: 70px;
         height: 70px;
      }
      .media-body {
         h2 {
            font-size: 1.275rem;
         }
      }
   }
   .all-mails-list {
      .small.text-muted {
         display: none;
      }
      .emails {
         width: 100% !important;
      }
   }
}

@media (max-width: 500px) and (min-width: 320px) {
   .userProfile-wrapper {
      .profile-top {
         .profile-content {
            top: 5px !important;
            left: 0px;
         }
      }
      .messages-wrapper {
         ul {
            li {
               &:not(:first-child) {
                  display: inline-block !important;
               }
               span,
               button {
                  margin-bottom: 10px !important;
               }
            }
         }
         .toolbar {
            label,
            button {
               margin: 0;
            }
         }
      }
   }
}

@media (max-width: 415px) and (min-width: 320px) {
   .report-status {
      .top-filter {
         .w-20 {
            width: 100% !important;
         }
      }
   }
   .profile-content {
      img {
         width: 50px;
         height: 50px;
      }
   }
}

/*================= Responsive scroll height ====================*/

@media(max-width: 768px) {
   .badge-extension {
      font-size: 0.8rem;
      width: 1.5rem;
      height: 1.5rem;
   }
}

@media(min-width: 768px) {
   .rct-sherpa-scroll {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: calc(100vh - 100px); // 100vh - 100px
      overflow-y: scroll;
   }
}

@media(max-width: 767px) and (min-width: 321px) {
   .rct-sherpa-scroll {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: calc(100vh - 200px); // 100vh - 360px
      overflow-y: scroll;
   }
}

@media(max-width: 320px) {
   .app-container
      .userProfile-wrapper {
         .rct-scroll {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: calc(100vh - 300px);
            overflow-y: scroll;
         }
      }
   .rct-sherpa-scroll {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: calc(100vh - 200px); // 100vh - 360px
      overflow-y: scroll;
   }
}

@media(max-width:1700px) {
   .app-boxed {
      .chat-main-body {
         .rct-scroll {
            height: calc(100vh - 369px) !important;
         }
      }
   }
}
.app-horizontal {
   .todo-wrapper {
      .list-wrap .task-detail-wrapper {
         .rct-scroll {
            height: calc(100vh - 242px) !important;
         }
      }
   }
}
.app-boxed {
   .todo-wrapper {
      .list-wrap{
         .rct-scroll {
            height: calc(100vh - 382px) !important;
         }
      }
   }
}
@media(max-width:990px) {
   .app-boxed {
      .rct-page-content {
         height: calc(100vh - 10rem) !important;
      }
      .chat-main-body,
      .chat-sidebar .chat-list {
         .rct-scroll {
            height: calc(100vh - 275px) !important;
         }
      }
      .rct-mail-wrapper {
         .list-wrap {
            .rct-scroll {
               height: calc(100vh - 211px) !important;
            }
         }
      }
      .todo-wrapper {
         .list-wrap{
            .rct-scroll {
               height: calc(100vh - 272px) !important;
            }
         }
      }
   }
}


@media(max-width:959px) {
   .chat-box-main .sidebar-toggler,.chat-main-body .chat-sidebar-toggler {
      display: block !important;
   }
   .chat-sidebar {
      .chat-list {
         .rct-scroll {
            height: calc(100vh - 232px) !important;
         }
      }
   }
   .app {
      .all-mails-list {
         .rct-scroll {
            height: calc(100vh - 135px) !important;
         }
      }
   }
   .todo-sidebar-wrap {
      .rct-scroll {
         height: calc(100vh - 221px) !important;
      }
   }
   .mail-sidebar-wrap {
      .rct-scroll {
         height: calc(100vh - 221px) !important;
      }
   }
}

@media(max-width:599px) {
   .todo-wrapper{
      .list-wrap{
         .rct-scroll{
            height: calc(100vh - 180px) !important;
         }
      }
   }
}

/*========================== Responsive App ===================*/

@media(max-width:767px) {
   .rct-mail-wrapper {
      .list-wrap {
         .icon-wrap,
         .emails .avatar-wrap,
         .emails .media-body p {
            display: none !important;
         }
         .list-item {
            padding: 0.75rem !important;
            .emails .media-body .w-25 {
               width: 100% !important;
            }
         }
      }
   }
}
@media(max-width:700px) {
   .shop-listing{
      .media{
         .media-body{
            .d-flex{
               margin-bottom:30px;
            }
         }
      }
   }
}
@media(max-width:1024px) {
   .right-panel-scroll{
      height: calc(100vh - 199px);
   }
}
//============ CRM Projects page ==========//
.search-bar-wrap{
   .rct-block-content{
      padding:30px;
   }
   .search-bar{
      width:calc(100% - 100px);
      >div{
         width:calc(100% - 110px);
      }
   }
   .add-project-wrap{
      width:100px;
   }
}
.projects-wrapper{
   .projects-icon{
      .btn-icon{
         width:50px;
         min-width: auto;
         height:50px;
         border-radius:100%;
         i{
            margin-left: 0 !important;
            font-size: 24px;
            margin:0;
         }
      }
   }
}
.project-detail-wrapper{
   .breadcrumb{
      a:last-child{
         color:#727891 !important;
      }
      .active.breadcrumb-item{
         display: none;
      }
   }
   .basic-info{
      ul{
         list-style-position: inside;
      }
   } 
}
//============= client page ==============
.clients-wrapper{
   .client-post{
      position: relative;
      .client-action{
         position: absolute;
         right:-15px;
         bottom:-11px;
         opacity:0;
         visibility:hidden;
         transition:all 0.3s ease-in-out ;
         button{
            padding: 5px !important;
            width:auto;
            min-width:auto;
            i{
               font-size: 20px;
               color:$gray-700;
            }
         }
      }
      &:hover{
         .client-action{
            opacity:1;
            visibility:visible;
         }
      }
   }
   .client-tab-wrap{
      .rct-block:hover{
         box-shadow:0px 1px 15px 1px rgba($primary, 0.3);
      }
   }
}
.client-dialog{
   > div:nth-child(3){
		> div:first-child{
			width: 100%;
			#form-dialog-title{
				padding: 24px;
			}
			> div:nth-child(2){
				padding: 0 24px 24px;
			}
		}
   }
}
.confirmation-dialog h4{
	color:$gray-900;
}
@media(max-width:991px){
   .clients-wrapper{
      .client-post{
         .client-action{
            opacity:1;
            visibility:visible;
            transition:all 0.3s ease-in-out ;
         }
      }
   }
}
@media(max-width:575px) {
   .search-bar-wrap {
      .rct-block-content {
         padding: 15px;
      }
      .search-bar {
         width: 100%;
         margin-bottom:10px;
         > div {
            padding-right:15px;
            width: calc(100% - 75px);
         }
      }
      .add-project-wrap {
         width: 100%;
     }
   }
   #chartdiv text{
      font-size:12px;
   }
}
@media(max-width:374px) {
   .project-detail-wrapper{
      .detail-list-wrap{
         li{
            display:block !important;
         }
      }    
   }
   .ReactTable{
      .-pagination{
         display: block !important;
         .-previous,.-next{
            display: block !important;
            margin: 10px 0;
         }
      }
   }
}
.addbutton {
   font-family: 'Lato';
   font-weight: 500;
   font-size: 30px;
   font-style: normal;
   opacity: 0.90;
   background-color: #008C95;
   color: #FFFFFF;
   width: 40px;
   height: 40px;
   border: 0px;
   border-radius: 12px;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.addbutton:hover{
   background: rgba(64, 192, 228, .9);
}


.ls-react-pdf {
   width: 100%;
   background-color: #474b4c;
   border: 2px solid #000;
   overflow:auto;
   position: relative;
   .react-pdf__Document {
      display: flex;
      flex-flow: column;
      .react-pdf__Page {
         margin: auto;
         padding: 12px;
         background-color: #474b4c;
         .react-pdf__textContent {
            mark {
            opacity: 0.5;
            background: #ff0;
            box-shadow: 0px 2px 10px #ff0;
            color: transparent;
            white-space: pre;
            }
         }
      }
  }

   .react-pdf__message {
      padding: 50px;
      color: white;
      margin: auto;
   }
}

.react-pdf-panel {
   position: relative;
   .pageScale {
      background-color: #323639;
      color: #FFFFFF;
      box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
      border-radius: 4px;
      .MuiSlider-root {
         width: 100px;
         padding: 16px 0;
      }
   }
   .bottom-absolute {
      position: absolute;
      left: 12px;
      right: 12px;
      bottom: 12px;
      opacity: 0;
      transition: opacity ease-in-out 0.2s;
   }
   .page-controls {
      background-color: #323639;
      color: #FFFFFF;
      box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
      border-radius: 4px;
   }
   
   .page-controls span {
      font: inherit;
      font-size: 0.8em;
      padding: 0 0.5em;
   }
   .MuiSlider-root {
      color: #FFFFFF;
   }
   .MuiIconButton-root {
         .MuiSvgIcon-root {
            color: #FFFFFF;
         }
   }
}

.react-pdf-panel:hover .bottom-absolute {
   opacity:1;
}

@media(max-width: 700px) {
   .react-pdf-panel .bottom-absolute {
      opacity:1;
   }
}

.notices-panel {
   height: 100vh;
   .notices-history-page {
      height: calc(100vh - 60px);
   }
   .notices-list-page {
      height: calc(100vh - 60px);
   }
   .notice-form-page {
      height: calc(100vh - 60px);
   }
   .alert-view-page {
      height: calc(100vh - 60px);
      overflow-x: hidden;
      overflow-y: auto;

      @media(max-width: 700px) {
         video {
             height: calc(100vh - 155px);
         }
         iframe {
             height: calc(100vh - 155px);
         }
         .image-container {
             height: calc(100vh - 155px);
         }
         .ls-react-pdf {
             height: calc(100vh - 155px) !important;
         }
     }
     @media(min-width: 701px) {
         video {
             height: calc(100vh - 110px);
         }
         iframe {
             height: calc(100vh - 110px);
         }
         .image-container {
             height: calc(100vh - 110px);
         }
         .ls-react-pdf {
             height: calc(100vh - 110px) !important;
         }
     }
     video {
         width: 100%;
         max-height: 100%;
         max-width: 100%;
         object-fit: contain;
         border-radius: 0px;
         background-color: #000;
     }
     .image-container {
         width: 100%;
         background-color: #000;
         border: 2px solid #000;
         overflow-y: auto;
     }
   }
}


div.spinner {
   position: relative;
   width: 42px;
   height: 42px;
   display: inline-block;
   background: transparent;
   padding: 10px;
   border-radius: 10px;
 }
 
 div.spinner div {
   width: 5px;
   height: 13px;
   background-color: var(--headerBackgroundColor) !important;
   position: absolute;
   left: 46%;
   top: 40%;
   opacity: 0;
   // box-shadow: 0 0 3px rgba(0,0,0,0.2);
   animation: fade 1s linear infinite;
   border-radius: 30%;
 }
 
 @keyframes fade {
   from {opacity: 1;}
   to {opacity: 0.25;}
 }

 div.spinner div.bar1 {
   transform:rotate(0deg) translate(0, -92%);
   animation-delay: 0s;
 }    

 div.spinner div.bar2 {
   transform:rotate(45deg) translate(0, -92%); 
   animation-delay: -0.875s;
 }

 div.spinner div.bar3 {
   transform:rotate(90deg) translate(0, -92%); 
   animation-delay: -0.75s;
 }
 div.spinner div.bar4 {
   transform:rotate(135deg) translate(0, -92%); 
   animation-delay: -0.625s;
 }
 div.spinner div.bar5 {
   transform:rotate(180deg) translate(0, -92%); 
   animation-delay: -0.5s;
 }
 div.spinner div.bar6 {
   transform:rotate(225deg) translate(0, -92%); 
   animation-delay: -0.375s;
 }
 div.spinner div.bar7 {
   transform:rotate(270deg) translate(0, -92%); 
   animation-delay: -0.25s;
 }
 div.spinner div.bar8 {
   transform:rotate(315deg) translate(0, -92%); 
   animation-delay: -0.125s;
 }
 
