.mini-chat-panel-sections {
    position: absolute;
    z-index: 100;
    right: 0px;
    bottom: 0px;
    .chat-sections {
        display: flex;
        .session-wrapper-theme-support {
            background-color: transparent;
            .list-card {
                background-color: transparent;
                .chat-title {
                    font-family: "Heebo",sans-serif;
                }
                .active-status-icon {
                    color: #1e8e3e;
                    font-size: 15px;
                    margin-top: -2px;
                }
                .inactive-status-icon {
                    color: red;
                    font-size: 15px;
                    margin-top: -2px;
                }
            }
        }
        .chatsection {
            width: 329px;
            height: 434px;
            margin-right: 10px;
            .rct-block-title {
                padding: 8px;
                .header-icon-button {
                    padding: 4px;
                    .MuiSvgIcon-root{
                        font-size: 20px;
                    }
                }
            }
            .rct-block {
                border-radius: 10px;
            }
            .menu-icon-button {
                padding: 8px;
            }
            .chat-footer {
                .form-control {
                 background-color: transparent;
                 border: 0px solid #EBEDF2;
                 padding: 5px 13px;
                 font-size: 15px;
                }
                .form-control:focus {
                    border-color: transparent;
                    box-shadow: none;
                 }
                 .chat-msg-input-field {
                    height: 36px;
                    padding: 4px 20px;
                 }
            }
            .chat-panel-messges {
                .scroll-to-bottom-badge{
                    bottom: 15px;
                    right: 15px;
                }
                .scroll-to-bottom-box {
                    bottom: 15px;
                    right: 15px;
                }
            }
            
        }
        .minimized-section {
            height: 38px;
        }
    }
    .list-card {
        margin-bottom: 0px;
    }
}

.full-screen-chat-wrapper {
    .chat-content {
      .chat-main-body {
        .chat-head {
          .media {
            .media-body {
                .active-status-icon {
                    font-size: 11px;
                    margin-top: 0px;
                }
            }
          }
        }
        .chat-footer {
            .form-control {
              width: 100%;
              padding: 10px 20px;
              font-size: 18px;
            }
            .form-control:focus {
               border-color: transparent;
               box-shadow: none;
            }
        }
        .chat-panel-messges {
            .scroll-to-bottom-badge{
                bottom: 15px;
                right: 15px;
            }
            .scroll-to-bottom-box {
                bottom: 15px;
                right: 15px;
            }
        }
      }
    }
  }
  .lifesherpa-communications {
      min-height: 445px;
      .chat-tab-bar {
          background-color: #ebebe0;
          .selectedTab {
              background-color: #008C95;
              color: white;
              border-radius: 5px;
          }
          .unSelectedTab {
            background-color: #ebebe0;
            color: #008C95;
          }
      }
      .video-groups-list-item {
        .delete-icon {
          display: none;
        }
      }
      .video-groups-list-item:hover {
        .delete-icon {
          display: flex;
        }
      }
      
      .contacts-list-item  {
        h4 {
            margin-bottom: 0px;
        } 
        .status-text {
          font-size: 14px;
        }
      }
      .add-group-button {
        background-color: #008C95;
        color: white;
        .MuiSvgIcon-root {
            color: white;
        }
      }
      .add-video-group-panel {
        .add-group-header {
          background-color: #008C95;
          color: white;
          .MuiSvgIcon-root {
              color:white;
          }
          .MuiIconButton-label {
            color: white;
            font-size: 14px;
          }
          .save-button {
            font-size: 16px;
          }
        }
        h4 {
            margin-bottom: 0px;
        }
        .alternate-color {
            background-color: #ffffff;
        }
        .selected-input-container {
            border-bottom: 3px solid #008C95;
        }
        .selected-text {
            color: #008C95;
        }
        .user-later-avatar {
            background-color: #008C95;
            color: #ffffff;
        }
        .primary-button {
            background-color: #008C95;
            color: white !important;
            border: 2px solid #008C95;
        }
      }
      .user-later-avatar {
        background-color: #008C95;
        color: #ffffff;
        .MuiSvgIcon-root {
            color: #ffffff;
        }
       } 
       .join-group-call-button {
            background-color: #008C95;
            color: #FFFFFF;
            border: 2px solid #008C95;
            font-family: 'Open sans', sans-serif;
            font-weight: 400;
            min-width: 100px;
            height: 40px;
            border-radius: 30px;
            font-size: 16px;
       }
       .localMessage {
           background-color: #008C95;
           color: white;
       }
       .userMessage {
        background-color: #262d31;
        color: white;
       }
       .fixed-bottom {
           z-index: 99;
           left: unset;
       }
    @media(max-width: 767px) and (min-width: 321px) {
        .rct-sherpa-scroll {
            height: calc(100vh - 50px); // 100vh - 360px
        }
    }
    .group-icon {
        font-size: 45px !important;
    }
  }
  .cps {
      cursor: pointer;
  }
  .add-group-contact-list-popup {
    .alternate-color {
        background-color: #ffffff;
    }
    .user-later-avatar {
        background-color: #008C95;
        color: #ffffff;
       } 
  }
  .alternate-color {
    background-color: #ffffff;
  }
  .message-text {
    padding: 7px;
    border-radius: 10px;
    overflow-wrap: anywhere;
  }
  .chat-date-style {
    font-size: 10px;
    margin: 10px 5px;
    min-width: 45px;
  }
  .chat-footer {
    .form-control {
        width: 100%;
        padding: 10px 20px;
        font-size: 18px;
        border-radius: 24px;
    }
    .form-control:focus {
       border-color: transparent;
       box-shadow: none;
    }
}
.lifesherpa-communications {
    .chat-panel-messges {
        .lifesherpa-chat-panel-header {
          .user-later-avatar {
            font-size: 25px;
          }
        }
        @media(max-width: 425px){
            .chat-item-list {
                height: calc(100vh - 330px) !important;
            }
        }
        @media(max-width: 768px) and (min-width: 426px) {
            .chat-item-list {
                height: calc(100vh - 302px) !important;
            }
        }
        @media(min-width: 769px) {
            .chat-item-list {
                height: calc(100vh - 308px) !important;
            }
        }
    }
    
}


.vidio-commuinication-panel {
    position: absolute;
    z-index: 101;
    right: 0px;
    bottom: 0px;
    top:0px;
    left:0px
}

.vidoe-call-confirmation-details {
    background-color: #3c4043;
    color: #ffffff;
    min-width: 350px;
    min-height: 300px;
    .caller-image {
        min-height: 150px;
        .MuiAvatar-root {
          width: 70px;
          height: 70px;
          font-size: 38px;
        }
    }
    
}
.vidoe-call-confirmation-actions {
    background-color: #3c4043;
    justify-content: space-between !important;
    min-height: 131px;
    padding: 33px !important;
        .MuiIconButton-root {
          width: 80px;
          height: 80px;
          color: #ffffff;
          z-index: 2;
          .MuiSvgIcon-root {
              font-size: 37px;
          }
        }
        .reject-video-call {
            background-color: #ef3535;
            &:hover {
                background-color: #ef3535;
            }
        }
        .accept-video-call {
            background-color: #14bd24;
            &:hover {
                background-color: #14bd24;
            }
        }
        .accept-video-call::before {
           content: "";
           position: absolute;
           width: 100%;
           height: 100%;
           background-color: inherit;
           border-radius: 50%;
           animation: rippleEffect 1.5s ease-out infinite;
           z-index: -1;
        }
        .reject-video-call::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: inherit;
            border-radius: 50%;
            animation: rippleEffect 1.5s ease-out infinite;
            z-index: -1;
         }
}
@keyframes rippleEffect {
    from {
        opacity: 1;
        transform: scale(0);
    }
    to {
        opacity: 0;
        transform: scale(3);
    }
}


.room {
    display: flex;
    position: relative;
    background-color: #202124;
    color: #FFFFFF;
    .inactive-button {
        background-color: #3c4043;
        color: #FFFFFF;
        &:hover {
         background-color: #3c4043;
        }
        padding: 12px;
    }
    .dominantSpeakerStyle {
        border: 4px solid #669df6;
        border-radius: 8px;
    }
    .active-button {
        background-color: #ea4335;
        color: #FFFFFF;
        &:hover {
            background-color: #ea4335;
        }
        padding: 12px;
    }
    .MuiBadge-colorPrimary {
        background-color: #ea4335;
        color: #FFFFFF;
    }
    .leave-call-button {
        padding: 8px 20px;
        border-radius: 61px;
        .MuiSvgIcon-root {
            font-size: 30px;
        }
    }
    .video-participant {
      width: 98%;
      height: 92%;
      background-color: #3c4043;
      border-radius: 12px;
    }
    .ping-layout-container {
        width: 100%;
        height: 92%;
        .video-participant {
            width: 100%;
            height: 100%;
            margin: 0px;
            min-width: 100%;
            min-height: 100%;
        }
        .group-video-participant {
            height: 100%;
            width:100%
        }
        .participant-container {
            min-height: 100%;
            min-width: 100%;
        }
    }
    .group-video-participant {
        width: 100%;
        height: 92%;
        display: grid;
        gap: 10px;
        padding: 0px 10px;
        .child-video-participant {
           width: 100%;
           height: 100%;
           background-color: #3c4043;
           border-radius: 12px;
        }
    }
    .mini-video-participant {
        position: fixed;
        bottom: 80px;
        right: 9px;
        width: 200px;
        height: 135px;
        border: 2px solid #ecf1f3;
        background-color: #3c4043;
        border-radius: 8px;
    }
    .participant-container {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      video {
            width: 100%;
            max-height: 100%;
            height: 100%;
            max-width: 100%;
            min-height: 100%;
            min-width: 100%;
      }
      .disbled-video-icon {
        position:absolute;
        left: 47%;
        top: 45%;
        .MuiAvatar-root {
            height: 100%;
            font-size: xxx-large;
            width: 100%;
        }
        .MuiAvatar-colorDefault {
            color: #ffffff;
            background-color: #FF2B85;
        }
       }
       .video-screen-options {
            position: absolute;
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
            justify-content: center;
            display: flex;
            align-items: center;
            .options-list {
                opacity: 0.8;
                background-color: #202124;
                color: #FFFFFF;
                display: flex;
                border-radius: 30pc;
                visibility: hidden;
                .MuiIconButton-root {
                   .MuiSvgIcon-root {
                    font-size: 20px;
                    color: #FFFFFF;
                   }
                }  
            }
       }
       .video-screen-options:hover {
        .options-list {
            visibility: visible;
        }
       }
       .disabled-video-avatar {
        position:absolute;
        left:0px;
        right: 0px;
        display: flex;
        justify-content: center;
        top: 0px;
        bottom: 0px;
        align-items: center;
        .MuiAvatar-root {
            height: auto;
            font-size: 3vw;
            width: auto;
        }
        .MuiAvatar-colorDefault {
            color: #ffffff;
            background-color: #FF2B85;
        }
       }
       .mic-icon-on-video {
        position: absolute;
        right: 5px;
        top: 5px;
        .mic-icon-button-onvideo {
            background-color: #3c4043;
            color: #ffffff;
            width: 20px;
            height: 20px;
            .MuiSvgIcon-root {
               font-size: 19px;
            }
        }
       }
       .video-invisible {
            width: 0px;
            height: 0px;
            min-width: 0px;
            min-height: 0px;
        }
        .client-name-on-video {
            position: absolute;
            left: 8px;
            bottom: 1px;
        }
    }
}

.members-count-details {
    margin-top: -5px;
}
.exitDialog {
    min-height: 75px;
    padding-top: 20px;
}
.video-room-participant-list {
    .MuiDialog-paperWidthSm {
        min-width: 350px;
    }
    .actvity-routine {
        h4 {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
        }
    }
   
}
.skip-dependent-task-text {
    font-size: 12px;
}
.all-clients-for-grooup {
    .MuiInputBase-input {
        color: white;
    }
    .MuiInput-underline:after {
        border-bottom: 2px solid white;
    }
}

.warning-dialog-popup {
    .MuiDialog-container {
        .MuiDialog-paper {
            max-width: 350px !important;
        }
    }
    .MuiDialogContent-root  {
        overflow: hidden;
        .execute-activity-alert {
            .exitDialog {
                min-height: 40px;
            }
            .subHeading {
                font-size: 15px;
            }
            .MuiAvatar-root {
                border: 1px solid rgb(66, 65, 65);
                padding: 5px;
                width: 50px;
                height: 50px;
            }
        }
    }
    .MuiDialog-paperFullWidth {
        min-width: 320px;
    }
}
.videocallConfirmationDialog {
    .MuiDialog-paperWidthSm {
        overflow: hidden;
    }
}

.chat-msg-input-field {
    resize: none;
    width: 100%;
    border-radius: 25px;
    height: 46px;
    padding: 9px 20px;
    font-size: 16px;
    margin-top: 5px;
}

.unavailable-cross-style {
    background: linear-gradient(to top left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 1px), rgba(0,0,0,1) 50%, rgba(0,0,0,0) calc(50% + 1px), rgba(0,0,0,0) 100%);
    top: 0px;
    right: 0px;
    bottom: opx;
    bottom: 0px;
    left: 0px;
    z-index: 1000;
    width: 40px;
    height: 40px;
    position: absolute;
}
.chat-panel-messges {
    position: relative;
    .scroll-to-bottom-badge{
        position: absolute;
        bottom: 79px;
        right: 25px;
        z-index: 101;
        .scroll-to-bottom-icon {
            width: 30px;
            height: 30px;
        }
    }
    .scroll-to-bottom-box {
        position: absolute;
        bottom: 79px;
        right: 25px;
        .scroll-to-bottom-icon {
            width: 30px;
            height: 30px;
            z-index: 100;
        }
    }
    
}
@keyframes dot-keyframes {
    0% {
      opacity: .4;
      transform: scale(1, 1);
    }
  
    50% {
      opacity: 1;
      transform: scale(1.2, 1.2);
    }
  
    100% {
      opacity: .4;
      transform: scale(1, 1);
    }
}
.loading-dots {
    text-align: center;
    width: 100%;
    
    &--dot {
      animation: dot-keyframes 1.5s infinite ease-in-out;
      background-color: #008C95;
      border-radius: 10px;
      display: inline-block;
      height: 7px;
      width: 7px;
      margin:3px ;
      &:nth-child(2) {
        animation-delay: .5s;
      }
      
      &:nth-child(3) {
        animation-delay: 1s;
      }
    }
}

.fixed-overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 99;
    align-items: center;
    overflow: hidden;
    margin: 0 auto;
    .add-activity-options-bottom-container {
        .MuiIconButton-root {
            background-color: #ffffff;
            color: #080808;
            padding: 11px;
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14);
        }
      .close-activity-options-button {
        padding: 14px;
      }
      .activity-type {
          color: #ffffff;
      }
      .option-button {
          margin-right: 4px;
      }
    }
}
.cursor-pointer{
    cursor: pointer;
}

.add-routine-button {
    .fixed-bottom {
        z-index: 99;
        .add-activity-bottom-container {
            .MuiIconButton-root {
                background-color: #ffffff;
                color: #080808;
                box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14);
                padding: 14px;
            }
        } 
    }
}

