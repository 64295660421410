.activity-template-ls-default {
    .update-time-buttons {
        .MuiButton-contained.Mui-disabled {
            background-color: #008C95;
            color: #FFFFFF;
        }
    }
    .update-passwords-button {
        .MuiButton-contained.Mui-disabled {
            background-color: #008C95;
            color: #FFFFFF;
        }
    }
    .MuiButton-contained.Mui-disabled {
        background-color: #008C95;
        color: #FFFFFF;
    }
    .activity-preveiw-skip-buttons {
        .MuiButton-contained.Mui-disabled {
            background-color: #008C95;
            color: #FFFFFF;
        }
    }
    .MuiDialogTitle-root {
        padding: 0px; 
    }
    .template-header, .template-content, .template-actions, .MuiDialogContent-root {
        .chipStyle {
            color: #008C95;
            border: 2px solid #008C95;
            background: #FFFFFF;
        }
        .chipSelected {
            color: white;
            background: #008C95;                                                                                   
            .MuiSvgIcon-root {
                color: white;
            }
        }    
    }
    .activity-preview-dialog-content {
        padding: 0px 0px;
        .activity-step-list-view {
            margin-left: 0px;
            margin-right: 0px;
        }
     }
     .MuiDialogContent-root:first-child {
        padding-top: 0px;
    }
    
}

.activity-template-theme-support {
    .header-dialog-resources {
        background-color: var(--primaryButtonNormalStateColor);
    }
    .bg-lifesherpa {
        background-color: var(--headerBackgroundColor);
        .text-white {
            color: var(--headerTitleColor) !important;
        }
        .disableColorOnHeader {
            color: var(--disabledColorOnHeader);
        }
        .header-close-icon-button {
            color: var(--headerTitleColor)
        }
    }
    .theame-text{
        color: var(--progressBarColor);
    }
    
    div.spinner div {
        background-color: var(--headerBackgroundColor) !important;
    }
    .template-header {
        background-color: var(--headerBackgroundColor) !important;
    }
    .filter-button-cancel {
        color: var(--secondaryButtonTitleColor);
        border: 2px solid var(--secondaryButtonTitleColor);
        background-color:var(--secondaryButtonNormalStateColor);
    }
    .filter-button-apply {
        background-color: var(--primaryButtonNormalStateColor);
        color: var(--buttonTitleColor);
        border: 2px solid var(--primaryButtonNormalStateColor);
        &:hover {
        background-color: var(--primaryButtonNormalStateColor);
        }
    }
    .btn-primary {
        background: var(--primaryButtonNormalStateColor);
        color: var(--buttonTitleColor);
        min-height: 40px;
        padding: 9px 10px;
    }
    .check-color {
        color:var(--alternativeSecondaryButtonNormalStateColor);
    }
    .primary-button {
        background-color: var(--primaryButtonNormalStateColor);
        color: var(--buttonTitleColor) !important;
        border: 2px solid var(--primaryButtonNormalStateColor);
    }
    .next-button {
        background-color: var(--primaryButtonNormalStateColor);
        color: var(--buttonTitleColor) !important;
        border: 2px solid var(--primaryButtonNormalStateColor);
    }
    .skip-button {
        color: var(--secondaryButtonTitleColor) !important;
        border: 2px solid var(--secondaryButtonTitleColor);
        background-color:var(--secondaryButtonNormalStateColor);
    }
    .secondary-button {
        color: var(--primaryButtonNormalStateColor);
        border: 2px solid var(--primaryButtonNormalStateColor);
        background-color:var(--alternativeSecondaryButtonNormalStateColor);;
        &:hover {
            background: var(--primaryButtonNormalStateColor);
            color: var(--buttonTitleColor);
            border: 2px solid var(--primaryButtonNormalStateColor);
        }
    }
    .vidoe-call-confirmation-details {
        background-color: var(--headerBackgroundColor) !important;
        color: var(--headerTitleColor) !important;
        .MuiAvatar-colorDefault {
            background: var(--headerTitleColor);
            color: var(--headerBackgroundColor);
        } 
    }
    .vidoe-call-confirmation-actions {
        background-color: var(--headerBackgroundColor) !important;
    }
    .user-status-list-form {
        .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
            background: var(--checkboxTintColor) linear-gradient(
           180deg, var(--checkboxTintColor), var(--checkboxTintColor)) repeat-x;
        }
        .form-radio .form-check-input:checked ~ .form-check-label::before {
            background: var(--checkboxTintColor) linear-gradient(
           180deg, var(--checkboxTintColor), var(--checkboxTintColor)) repeat-x;
        }
        h3 {
            font-size: 17px;
        }
        .user-status-list-options {
            padding-top: 3px;
            padding-bottom: 3px;
            border-bottom: 1px solid var(--headerBackgroundColor) !important;
        }
        
    }
    .camera-popup-dialog-actions {
        background-color: var(--headerBackgroundColor) !important;
        .take-picture-message-camera {
            color: var(--headerTitleColor);
        }
        .inner-circle {
            background: var(--headerTitleColor) !important;
        }
    }
    .checkin-container {
        min-height: 450px;
        background-color: var(--headerBackgroundColor);
        z-index: 1;
        h1 , p {
            color: var(--headerTitleColor) !important;
        }
        .text-white {
            color: var(--headerTitleColor) !important;
        }
        color: var(--headerTitleColor) !important;
    } 
    .backbuttonForPreviewLinkIframe {
        color: var(--buttonTitleColor);
        border: 1px solid var(--buttonTitleColor);
        background-color:var(--primaryButtonNormalStateColor);;
        .MuiSvgIcon-root,.linkButton{
            color: var(--buttonTitleColor);
        }
        &:hover {
            background-color:var(--primaryButtonNormalStateColor);
            color: var(--buttonTitleColor);
            border: 1px solid var(--primaryButtonNormalStateColor);
            .MuiSvgIcon-root,.linkButton{
                color: var(--buttonTitleColor);
            }
        }
    }
    .update-time-buttons {
        .MuiButton-contained.Mui-disabled {
            color: var(--buttonTitleDisabledColor);
            box-shadow: none;
            background-color:var(--buttonDisabledStateColor);
            border: 1px solid var(--buttonDisabledStateColor);
        }
    }
    .update-passwords-button {
        .MuiButton-contained.Mui-disabled {
            color: var(--buttonTitleDisabledColor);
            box-shadow: none;
            background-color:var(--buttonDisabledStateColor);
            border: 1px solid var(--buttonDisabledStateColor);
        }
    }
    .MuiButton-contained.Mui-disabled {
        color: var(--buttonTitleDisabledColor) !important;
        box-shadow: none;
        background-color:var(--buttonDisabledStateColor);
        border: 1px solid var(--buttonDisabledStateColor);
    }
    .activity-preveiw-skip-buttons {
        .MuiButton-contained.Mui-disabled {
            color: var(--buttonTitleDisabledColor) !important;
            box-shadow: none;
            background-color:var(--buttonDisabledStateColor);
            border: 1px solid var(--buttonDisabledStateColor);
        }
    }
    .MuiDialogTitle-root {
        padding: 0px; 
        .MuiIconButton-label {
            color: var(--headerTitleColor);
        }
        .MuiButtonBase-root, h4, h2 {
           color: var(--headerTitleColor) !important;
       }
       .header-close-button {
          color: var(--headerTitleColor)
       }
    }
    .all-clients-for-grooup {
        .header-title {
            color: var(--headerTitleColor) !important;
        }
        .MuiSvgIcon-root {
            color: var(--headerTitleColor);
        }
        .MuiInputBase-input {
            color: var(--headerTitleColor);
        }
        .MuiInput-underline:after {
            border-bottom: 2px solid var(--headerTitleColor);
        }
    }
    .text-gray {
      color: var(--headlineTextColor);
    }
    .rct-picker {
        .MuiInputAdornment-root {
          .MuiIconButton-root {
            color: var(--primaryButtonNormalStateColor);
          }
        }
    } 
    .MuiPaper-root, .template-header, .template-actions, .MuiDialogActions-root {
        background-color: var(--screenContentBackgroundColor);
        color: var(--headlineTextColor);
        .text-secondary {
            color: var(--headlineTextColor) !important;
        }
        h4, h1 {
            color: var(--headlineTextColor);
        }
        .theame-color {
            background:var(--primaryButtonNormalStateColor);
            .text-white {
              color: var(--buttonTitleColor) !important;
            }
        }
        .custom-control-label {
            color: var(--headlineTextColor);
        }
        .patcipant-list-button {
            color: var(--headlineTextColor);
        }
        .error-list-item {
            h4 {
                color: var(--errorMessage);
            }
        }
        .styleToListItem {
            background-color: #eceff3 !important;
            color: var(--primaryButtonNormalStateColor);
            .MuiIconButton-label {
              color: var(--primaryButtonNormalStateColor);
            }
        }
        .defaultStyleToListItem {
            color: var(--primaryButtonNormalStateColor);
            .MuiIconButton-label {
              color: var(--primaryButtonNormalStateColor);
            }
        }
        .activity-filter-icon {
            .MuiButtonBase-root {
              background: var(--primaryButtonNormalStateColor) !important;
              border: 1px solid var(--primaryButtonNormalStateColor);
              .filter-icon {
                background-color: var(--primaryButtonNormalStateColor);
                fill: var(--buttonTitleColor);
               }
              .MuiSvgIcon-root {
                color: var(--buttonTitleColor);
              }
            }
          } 
          .activity-filter-icon-applied {
            .MuiButtonBase-root {
              color: var(--secondaryButtonTitleColor);
              border: 1px solid var(--secondaryButtonTitleColor);
              background:var(--secondaryButtonNormalStateColor) !important;
              .filter-icon {
                background-color: var(--secondaryButtonNormalStateColor);
                fill: var(--secondaryButtonTitleColor);
               }
              .MuiSvgIcon-root {
                color: var(--secondaryButtonTitleColor);
              }
            }
          } 
          .notice-history-alerts-panel-header {
            border-bottom: 1px solid var(--primaryButtonNormalStateColor);
          }
        .MuiDialogContent-root {
            background-color: var(--screenContentBackgroundColor);
            color: var(--headlineTextColor);
            .MuiIconButton-root{
                color: var(--headlineTextColor);
            }
            .progressbar_style{
                border: 2px solid var(--headlineTextColor);
                border-radius: 5px;
            }
            .rct-picker {
                .MuiInputAdornment-root {
                  .MuiIconButton-root {
                    color: var(--primaryButtonNormalStateColor);
                  }
                }
            } 
            .help-panel-container {
                .add-video-group-panel {
                  .add-group-header {
                    background-color: var(--headerBackgroundColor);
                    color: var(--headerTitleColor);
                    .MuiSvgIcon-root {
                      color: var(--headerTitleColor);
                    }
                    .MuiIconButton-label {
                      color: var(--headerTitleColor);
                    }
                    .save-button {
                      color: var(--headerTitleColor);
                    }
                  }
                  .list-card {
                    color: var(--headlineTextColor);
                  }
                  .selected-input-container {
                    border-bottom: 3px solid var(--primaryButtonNormalStateColor);
                  }
                 .selected-text {
                    color: var(--primaryButtonNormalStateColor);
                  }
                  .primary-button {
                    background-color: var(--primaryButtonNormalStateColor);
                    color: var(--buttonTitleColor) !important;
                    border: 2px solid var(--primaryButtonNormalStateColor);
                  }
                  .user-later-avatar {
                    background-color: var(--primaryButtonNormalStateColor);
                    color: var(--buttonTitleColor);
                    .MuiSvgIcon-root {
                      color: var(--buttonTitleColor);
                    }
                  }
                }
                .user-later-avatar {
                  background-color: var(--primaryButtonNormalStateColor);
                  color: var(--buttonTitleColor);
                  .MuiSvgIcon-root {
                    color: var(--buttonTitleColor);
                  }
                }
                .join-group-call-button {
                    background-color: var(--primaryButtonNormalStateColor);
                    color: var(--buttonTitleColor) !important;
                    border: 2px solid var(--primaryButtonNormalStateColor);
                }
                .localMessage {
                  background-color: var(--primaryButtonNormalStateColor);
                  color: var(--buttonTitleColor);
                }
                .userMessage {
                  background-color: var(--secondaryButtonNormalStateColor);
                  color: var(--secondaryButtonTitleColor);
                  border:1px solid var(--secondaryButtonTitleColor);
                }
                .chat-footer {
                  .MuiIconButton-root {
                    color: var(--primaryButtonNormalStateColor);
                  }
                  .Mui-disabled {
                    color: var(--buttonDisabledStateColor)
                  }
                  .MuiIconButton-label {
                    color: none;
                  }
                }
                .chat-panel-messges {
                    .lifesherpa-chat-panel-header {
                      .user-later-avatar {
                        background-color: var(--headerTitleColor);
                        color: var(--headerBackgroundColor);
                      }
                    }
                    .scroll-to-bottom-icon {
                        background-color: var(--alternativeSecondaryButtonNormalStateColor);
                        color: var(--alternativeSecondaryButtonTitleColor);
                    }
                }
                .container-border {
                    border: 2px solid var(--headerBackgroundColor);
                }
            }
            .list-card {
                box-shadow:  0 1px 15px 1px rgba(0, 0, 0, .2);
                color: var(--headlineTextColor);
                background-color:var(--screenContentBackgroundColor);
                .theme-icon-color {
                    color: var(--primaryButtonNormalStateColor) !important;
                }
                .step-completed-checkbox {
                    color: var(--checkboxTintColor);
                }
                .step-skipped-icon {
                    background-color: var(--checkboxFailedTintColor);
                }
                .border-top {
                    border-top: 1px solid var(--primaryButtonNormalStateColor) !important;
                }
                .stepDo {
                    color: var(--primaryButtonNormalStateColor);
                }
                .done-button-thumb-icon {
                    color: var(--primaryButtonNormalStateColor);
                }
              }
              .user-later-avatar {
                background-color: var(--primaryButtonNormalStateColor);
                color: var(--buttonTitleColor);
              }
            .execute-activity-alert {
                .exitDialog {
                    color: var(--headlineTextColor);
                }
                .subHeading {
                    color: var(--subtextColor);
                }
                .MuiAvatar-root {
                    border: 1px solid var(--headlineTextColor);
                }
            }
        }
        .activity-preview-dialog-content {
            padding: 0px 0px;
            .activity-step-list-view {
                margin-left: 0px;
                margin-right: 0px;
            }
            .MuiButton-contained.Mui-disabled {
                color: var(--buttonTitleDisabledColor) !important;
                box-shadow: none;
                background-color:var(--buttonDisabledStateColor);
                border: 1px solid var(--buttonDisabledStateColor);
            }
         }
        .list-view-container {
            background-color: var(--screenContentBackgroundColor);
         }
        .user-status-profile-background {
           background-color: var(--headerBackgroundColor);
           color: var(--headerTitleColor);
           .text-secondary {
              color: var(--headerTitleColor) !important;
            }
            .border-bottom {
                border-bottom: 0px solid var(--buttonTitleColor) !important;
            }
        }
        .theme-header-background-style {
            background-color: var(--headerBackgroundColor);
            color: var(--headerTitleColor);
            .text-secondary {
                color: var(--headerTitleColor) !important;
            }
            h2, h4 {
                color: var(--headerTitleColor);
            }

        }
        .countdown-edit-button {
            color: var(--secondaryButtonTitleColor);
            border: 2px solid var(--secondaryButtonTitleColor);
            background-color:var(--secondaryButtonNormalStateColor);
        }
        .form-cancel-button {
            color: var(--secondaryButtonTitleColor);
            border: 2px solid var(--secondaryButtonTitleColor);
            background-color:var(--secondaryButtonNormalStateColor);
        }
        .primary-button {
            background-color: var(--primaryButtonNormalStateColor);
            color: var(--buttonTitleColor) !important;
            border: 2px solid var(--primaryButtonNormalStateColor);
        }
        .secondary-button {
            color: var(--primaryButtonNormalStateColor);
            border: 2px solid var(--primaryButtonNormalStateColor);
            background-color:var(--alternativeSecondaryButtonNormalStateColor);
            &:hover {
                background: var(--primaryButtonNormalStateColor);
                color:var(--buttonTitleColor);
                border: 2px solidvar(--primaryButtonNormalStateColor);
            }
        }
        a {
            color: var(--primaryButtonNormalStateColor);
        }
        .chipStyle {
            color: var(--secondaryButtonTitleColor);
            border: 2px solid var(--secondaryButtonTitleColor);
            background-color:var(--secondaryButtonNormalStateColor);
        }
        .chipSelected {
            background-color: var(--primaryButtonNormalStateColor);
            color: var(--buttonTitleColor);
            border: 2px solid var(--primaryButtonNormalStateColor);
            .MuiChip-avatar {
                color: var(--buttonTitleColor);
            }
        }
        .applyFilter {
            background: var(--primaryButtonNormalStateColor);
            color: var(--buttonTitleColor);
            border: 2px solid var(--primaryButtonNormalStateColor);
        }
        .helpbutton {
            color: var(--alternativeSecondaryButtonTitleColor);
            border: 2px solid var(--alternativeSecondaryButtonTitleColor);
            background: var(--alternativeSecondaryButtonNormalStateColor);
        }
        .helpbutton:hover, .helpbutton:focus{
            background: var(--alternativeSecondaryButtonNormalStateColor);
            color:var(--alternativeSecondaryButtonTitleColor);
            border: 0px solid var(--alternativeSecondaryButtonNormalStateColor);
        }
        .stepDo {
            color: var(--primaryButtonNormalStateColor);
        }
        .done-button-thumb-icon {
            color: var(--primaryButtonNormalStateColor);
        }
        .MuiButton-contained.Mui-disabled {
            color: var(--buttonTitleDisabledColor) !important;
            box-shadow: none;
            background-color:var(--buttonDisabledStateColor);
            border: 1px solid var(--buttonDisabledStateColor);
        }
        .chat-sidebar {
            border: 1px solid var(--headerBackgroundColor);
            .chat-list {
              .activity-histroy-list {
                .user-list-item {
                     color: var(--headlineTextColor);
                     box-shadow: none;
                     h5 {
                       color: var(--headlineTextColor);
                     }
                     span {
                       color: var(--subtextColor);
                     }
                     background-color: var(--screenContentBackgroundColor);
                     &.item-active {
                      background-color:none; 
                      border-top: 1px solid var(--screenContentBackgroundColor);
                      box-shadow: none;
                     }
                   &:hover {
                      &:not(.item-active) {
                        background-color: var(--screenContentBackgroundColor);
                        box-shadow: none;
                    }
                  }
                  .media {
                    .media-body {
                      .media-body-text {
                        color: var(--headlineTextColor);
                        h4 {
                            color: var(--headlineTextColor);
                        }
                      }
                      .media-body-sub-text {
                        color: var(--subtextColor);
                      }
                    }
                  }
                  .achievementStatusCancelled {
                    background-color: var(--achievementStatusCancelled);
                  }
                  .achievementStatusCompletedConfirmationNeeded {
                    background-color: var(--achievementStatusCompletedConfirmationNeeded);
                  }
                  .achievementStatusCompletedNoConfirmationNeeded {
                    background-color: var(--achievementStatusCompletedNoConfirmationNeeded);
                  }
                  .achievementStatusCompletedSkippedStepsNoConfirmationNeeded {
                    background-color: var(--achievementStatusCompletedSkippedStepsNoConfirmationNeeded);
                  }
                  .achievementStatusConfirmed {
                    background-color: var(--achievementStatusConfirmed);
                  }
                  .achievementStatusConfirmedNegative {
                    background-color: var(--achievementStatusConfirmedNegative);
                  }
                  .achievementStatusConfirmedSkippedSteps {
                    background-color: var(--achievementStatusConfirmedSkippedSteps);
                  }
                  .achievementStatusExpired {
                    background-color: var(--achievementStatusExpired);
                  }
                  .achievementStatusInProgress {
                    background-color: var(--achievementStatusInProgress);
                  }
                  .achievementStatusIncomplete {
                    background-color: var(--achievementStatusIncomplete);
                  }
                  .achievementStatusNotStarted {
                    background-color: var(--achievementStatusNotStarted);
                  }
                  .achievementStatusPaused {
                    background-color: var(--achievementStatusPaused);
                  }
                  .achievementStatusNotFound {
                    background-color: var(--disabledColorOnContent);
                  }
                 }
                .alternative-user-list-item {
                  background-color: #FFFFFF;
                }
                .item-active {
                    background-color: var(--headerBackgroundColor);
                    .media {
                      .media-body {
                        .media-body-text {
                          color: var(--headerTitleColor);
                          h4 {
                            color: var(--headerTitleColor);
                          }
                        }
                        .media-body-sub-text {
                          color: var(--headerTitleColor);
                        }
                      }
                    }
                  }
              } 
            } 
          }
        .heading-text {
            color: var(--headlineTextColor);
        }
        .sub-text {
            color: var(--subtextColor);
        }
        .header-text-color {
            color: var(--headerTitleColor);
        }
    }
    .activity-completed {
        background-color: var(--progressBarColor);
    }
    .lifesherpa-supported-logo {  
        padding: 2px 8px;
        background-color: #f8f8f8;
        border-radius: 3px;
        margin-left: 10px;
     }
     .activity-preview-theme-logo {
         background-color: var(--screenContentBackgroundColor);
         padding: 10px;
         border-radius: 5px;
     }
     .activity-preview-form-container {
        .form-body-background {
            
            background-color:var(--screenContentBackgroundColor) ;
            hr {
                background-color: var(--headerBackgroundColor);
            }
            .form-label {
                background-color: var(--headerBackgroundColor);
                color: var(--headerTitleColor);
                padding: 5px 10px;
                width: 100%;
                margin-bottom: 0px;
                font-size: 17px;
            }
            .form-description {
                padding: 5px 10px;
            }
            .form-margin { 
                margin: 2px 10px;
            }
            .bg-white {
                background-color: var(--screenContentBackgroundColor) !important
              }
            .mb-2 {
                margin-bottom: 0px !important;
            }
            p {
                font-size: 16px;
            }
            .custom-control-label {
                width: 100%;
                font-size: 15px;
            }
            .form-input-field {
                margin: 8px 10px;
                font-size: 16px;
            }
            .preveiw-options {
                margin: 0px;
                padding: 3px 10px;
                border-bottom: 1px solid var(--headerBackgroundColor);
            }
            .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
                background: var(--checkboxTintColor) linear-gradient(
            180deg, var(--checkboxTintColor), var(--checkboxTintColor)) repeat-x;
            }
            .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
                background: var(--checkboxTintColor) linear-gradient(
            180deg, var(--checkboxTintColor), var(--checkboxTintColor)) repeat-x;
            }
            .rct-picker {
                .MuiInputBase-root {
                    color: var(--headlineTextColor);
                }
                .MuiFormLabel-root {
                    color: var(--headlineTextColor);
                }
            }
        }
        .theme-header-background-style {
            .media-body {
                h4 {
                    color: var(--headerTitleColor);
                }
            }
            .helpUrl-icon {
                color: var(--headerTitleColor) !important;
            }
        }
        .theme-header-background-style {
            .media-body {
                h4 {
                    color: var(--headerTitleColor);
                }
            }
        }
     }
    .sweet-alert {
        color: var(--primaryButtonNormalStateColor);
        background-color:var(--screenContentBackgroundColor) !important;
        box-shadow: 0 1px 5px 1px var(--primaryButtonNormalStateColor)  !important;
        border: 0px solid var(--alternativeSecondaryButtonNormalStateColor);
        .sweet-alert-text {
            color: var(--primaryButtonNormalStateColor) !important;
        }
        h2 {
            color: var(--headlineTextColor) !important;
        }
        .btn-warning, .btn-info {
            color:var(--buttonTitleColor) !important;
            background: var(--alertButtonsColor);
            border:0px solid rgb(238, 162, 54);
            box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 1px inset, rgba(238, 162, 54,0.6) 0px 0px 8px;
            min-width: 120px;
            min-height: 2.8rem !important;
            padding: 0.6rem 2rem;
        }
        .btn-default {
            border:1px solid var(--alertButtonsColor);
            min-width: 120px;
            min-height: 2.8rem !important;
            padding: 0.6rem 2rem;
            color: var(--alertButtonsColor);
        }
        .btn-warning:not(:disabled):not(.disabled):active {
            color:var(--buttonTitleColor) !important;
            background-color: var(--alertButtonsColor);
        }
    }
    .btn-grey {
        color: var(--alternativeSecondaryButtonTitleColor);
        background: var(--alternativeSecondaryButtonNormalStateColor);
        border-color: var(--alternativeSecondaryButtonNormalStateColor);
        min-width: 100px;
        min-height: 2.8rem !important;
        padding: 0.6rem 2rem;
    }
    .btn-warning {
        color: var(--buttonTitleColor) !important;
        background: var(--primaryButtonNormalStateColor) !important;
        border-color: var(--primaryButtonNormalStateColor) !important;
        border: 0px solid var(--primaryButtonNormalStateColor);
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.8);
        min-width: 100px;
        min-height: 2.8rem !important;
        padding: 0.6rem 2rem;
    }

    .lifesherpa-session-theme-support {
        .achievementStatusCancelled {
            background-color: var(--achievementStatusCancelled);
        }
        .achievementStatusCompletedConfirmationNeeded {
            background-color: var(--achievementStatusCompletedConfirmationNeeded);
        }
        .achievementStatusCompletedNoConfirmationNeeded {
            background-color: var(--achievementStatusCompletedNoConfirmationNeeded);
        }
        .achievementStatusCompletedSkippedStepsNoConfirmationNeeded {
            background-color: var(--achievementStatusCompletedSkippedStepsNoConfirmationNeeded);
        }
        .achievementStatusConfirmed {
            background-color: var(--achievementStatusConfirmed);
        }
        .achievementStatusConfirmedNegative {
            background-color: var(--achievementStatusConfirmedNegative);
        }
        .achievementStatusConfirmedSkippedSteps {
            background-color: var(--achievementStatusConfirmedSkippedSteps);
        }
        .achievementStatusExpired {
            background-color: var(--achievementStatusExpired);
        }
        .achievementStatusInProgress {
            background-color: var(--achievementStatusInProgress);
        }
        .achievementStatusIncomplete {
            background-color: var(--achievementStatusIncomplete);
        }
        .achievementStatusNotStarted {
            background-color: var(--achievementStatusNotStarted);
        }
        .achievementStatusPaused {
            background-color: var(--achievementStatusPaused);
        }
        .achievementStatusNotFound {
            background-color: var(--disabledColorOnContent);
        } 
        .heading-text {
            color: var(--headlineTextColor);
        }
        .sub-text {
            color: var(--subtextColor);
        }
    }
    .invalida-card {
        border: 2px solid red;
    }
}

.activity-template-theme2 {
    .header-dialog-resources {
        background-color: #134266;
    }
    .bg-lifesherpa {
        background-color: #134266;
    }
    .template-header {
        background-color: #134266 !important;
    }
    .filter-button-cancel {
        color: #134266 ;
        border:2px solid #134266;
        background-color: #FFFFFF;
        &:hover {
           background-color: #103958;
           color: #ffffff;
        }
    }
    .filter-button-apply {
        color: #ffffff !important;
        background-color: #134266;
        border:2px solid #134266;
        &:hover {
          background-color: #103958;
        }
    }
    .btn-primary {
        background: #134266;
        color:#fff;
    }
    .check-color {
        color:#ffffff;
    }
    .primary-button {
        color: #ffffff !important;
        background-color: #134266;
        border:2px solid #134266;
        &:hover {
          background-color: #103958;
        }
    }
    .secondary-button {
        color: #134266 ;
        border:2px solid #134266;
        background-color: #FFFFFF;
        &:hover {
           background-color: #103958;
           color: #ffffff;
        }
    }
    .user-status-list-form {
        .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
            background: #134266 linear-gradient(
           180deg, #134266, #134266) repeat-x;
        }
    }
    .checkin-container {
        min-height: 450px;
        background-color: #134266;
        z-index: 1;
    } 
    .backbuttonForPreviewLinkIframe {
        color: #134266;
        border: 1px solid #134266;
        background-color: #FFFFFF;
        .MuiSvgIcon-root,.linkButton{
            color: #134266;
        }
        &:hover {
            background-color: #134266;
            color:#FFFFFF;
            border: 1px solid #FFFFFF;
            .MuiSvgIcon-root,.linkButton{
                color: #FFFFFF;
            }
        }
    }   
    .MuiPaper-root, .template-header, .template-actions, .MuiDialogActions-root {
        color: #134266;
        .text-secondary {
            color: #134266 !important;
        }
        .theame-color {
            background:#134266;
        }
        .form-submit-button {
            background:#134266;
        }
        .MuiDialogContent-root {
            background-color: #f8f8f8;
            color: #134266;
            .list-card {
                box-shadow: 0 1px 15px 1px #929fbb;
                color: #134266;
                background-color: #f8f8f8;
                .theme-icon-color {
                    color: #134266 !important;
                }
                .border-top {
                    border-top: 1px solid #6276a0 !important;
                }
              }
        }
        .countdown-edit-button {
            color: #134266;
            border: 2px solid #134266;
        }
        .form-cancel-button {
            color: #FFFFFF ;
            border:2px solid #134266;
            background-color: #134266;
            &:hover {
               background-color: #103958;
               color: #ffffff;
            }
        }
        .primary-button {
            color: #ffffff !important;
            background-color: #134266;
            border:2px solid #134266;
            &:hover {
              background-color: #103958;
            }
        }
        .secondary-button {
            color: #134266 ;
            border:2px solid #134266;
            background-color: #FFFFFF;
            &:hover {
               background-color: #103958;
               color: #ffffff;
            }
        }
        a {
            color: #134266;
        }
        .chipStyle {
            color: #134266;
            border: 2px solid #134266;
            background: #ffffff;
        }
        .chipSelected {
            background: #134266;
            color:#fff;
           .MuiChip-avatar {
                color:#fff ;
            }
        }
        .applyFilter {
            background: #134266;
            color: #ffffff;
            border: 2px solid #ffffff;     
        }
        .helpbutton {
            background: #ffffff;
            color: #134266;
            border: 2px solid #134266;
        }
        .helpbutton:hover, .helpbutton:focus{
            background-color:#134266;
            color:  #fff;
            border: 0px solid #134266;;
        }
    }
    .form-body-background {
        hr {
            background-color: #134266;
        }
        .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
            background: #134266 linear-gradient(
        180deg, #134266, #134266) repeat-x;
        }
        .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
            background: #134266 linear-gradient(
        180deg, #134266, #134266) repeat-x;
        }
    }
    .sweet-alert {
        .sweet-alert-text {
            color: #134266 !important;
        }
        .btn-warning, .btn-info {
          color: #ffffff !important;
          background: #134266;
          border:1px solid #134266;
          box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 1px inset, rgba(37, 34, 29, 0.6) 0px 0px 8px;
          min-width: 120px;
        }
    }
}

@media (min-width: 768px) {
    .activity-template-theme-support {
        
        .MuiDialog-paperWidthSm {
            min-width: 400px;
        }
       
        .MuiDialog-paperScrollPaper {
            min-width: 400px;
        }
    }
}

@media (max-width: 767px) {
    .activity-template-theme-support {
        .MuiDialog-paperFullWidth {
            width: calc(100% - 16px);
        }
        .MuiDialog-paperWidthSm {
            min-width: 350px;
        }
        .MuiDialog-paper {
            margin: 8px;
            position: relative;
            overflow-y: auto;
        }
        .MuiDialog-paperScrollPaper {
            display: flex;
            max-height: calc(100% - 24px);
            flex-direction: column;
            width: calc(100% - 0px);
           // min-width: 350px;
        }
    }
    .configurator-activity-preview {
        .MuiDialog-paperFullWidth {
            width: calc(100% - 0px);
        }
        .MuiDialog-paper {
            margin: 0px;
            position: relative;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .MuiDialog-paperScrollPaper {
            display: flex;
            max-height: calc(100% - 0px);
            flex-direction: column;
        }
    }
    .bullietin-post-viewer {
        .MuiDialog-paperFullWidth {
            width: calc(100% - 0px);
        }
    }
    .bullietin-post-video-viewer {
        .MuiDialog-paperFullWidth {
            width: calc(100% - 8px);
        }
    }
}

.activity-template-theme5 {
    .header-dialog-resources {
        background-color: #0f594e;
    }
    .bg-lifesherpa {
        background-color: #0f594e;
    }
    .template-header {
        background-color: #0f594e !important;
    }
    .filter-button-cancel {
        color: #0F594E;
        border: 2px solid #0F594E;
        background-color: #ffffff;
        &:hover {
            color: white;
            background-color:#7A9B32;
        }
    }
    .filter-button-apply {
        background-color: #ADCE65;
        color:#0f594e;
        border: 2px solid #7A9B32;
        &:hover {
            background-color: #7A9B32;
        }
    }
    .btn-primary {
        background: #0f594e;
        color:#fff;
    }
    .check-color {
        color:#ffffff;
    }
    .primary-button {
        background-color: #ADCE65;
        color:#0f594e;
        border: 2px solid #7A9B32;
        &:hover {
            background-color: #7A9B32;
        }
    }
    .secondary-button {
        color: #0F594E;
        border: 2px solid #0F594E;
        background-color: #ffffff;
        &:hover {
            color: white;
            background-color:#7A9B32;
        }
    }
    .user-status-list-form {
        .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
            background: #0f594e linear-gradient(
           180deg, #0f594e, #0f594e) repeat-x;
        }
    }
    .checkin-container {
        min-height: 450px;
        background-color: #0f594e;
        z-index: 1;
    } 
    .backbuttonForPreviewLinkIframe {
        color: #0f594e;
        border: 1px solid #0f594e;
        background-color: #FFFFFF;
        .MuiSvgIcon-root,.linkButton{
            color: #0f594e;
        }
        &:hover {
            background-color: #0f594e;
            color:#FFFFFF;
            border: 1px solid #FFFFFF;
            .MuiSvgIcon-root,.linkButton{
                color: #FFFFFF;
            }
        }
    }
    .MuiPaper-root, .template-header, .template-actions, .MuiDialogActions-root {
        color: #0f594e;
        .text-secondary {
            color: #0f594e !important;
        }
        .theame-color {
            background:#0f594e;
        }
        .form-submit-button {
            background:#0f594e;
        }
        .MuiDialogContent-root {
            background-color: #f8f8f8;
            color: #0f594e;
            .list-card {
                box-shadow: 0 1px 15px 1px rgba(119, 125, 124, 0.7);
                color: #0f594e;
                background-color:#f8f8f8;
                .theme-icon-color {
                    color: #0f594e !important;
                }
                .border-top {
                    border-top: 1px solid #2c3d3e  !important;
                }
              }
        }
        .countdown-edit-button {
            color: #0f594e;
            border: 2px solid #0f594e;
        }
        .form-cancel-button {
            color: #0F594E;
            border: 2px solid #0F594E;
            background-color: #ffffff;
            &:hover {
                color: white;
                background-color:#7A9B32;
            }
        }
        .primary-button {
            background-color: #ADCE65;
            color:#0f594e;
            border: 2px solid #7A9B32;
            &:hover {
                background-color: #7A9B32;
            }
        }
        .secondary-button {
            color: #0F594E;
            border: 2px solid #0F594E;
            background-color: #ffffff;
            &:hover {
                color: white;
                background-color:#7A9B32;
            }
        }

        a {
            color: #0f594e;
        }
        .chipStyle {
            color: #0f594e;
            border: 2px solid #0f594e;
            background: #ffffff;
        }
        .chipSelected {
            background: #0f594e;
            color:#fff;
           .MuiChip-avatar {
                color:#fff ;
            }
        }
        .applyFilter {
            background: #0f594e;
            color: #ffffff;
            border: 2px solid #ffffff;
           
        }
        .helpbutton {
            background: #ffffff;
            color: #0f594e;
            border: 2px solid #0f594e;
        }
        .helpbutton:hover, .helpbutton:focus{
            background-color:#0f594e;
            color:  #fff;
            border: 0px solid #0f594e;;
        }
    }
    .form-body-background {
        hr {
            background-color: #0f594e;
        }
        .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
            background: #0f594e linear-gradient(
        180deg, #0f594e, #0f594e) repeat-x;
        }
        .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
            background: #0f594e linear-gradient(
        180deg, #0f594e, #0f594e) repeat-x;
        }
    }
    .sweet-alert {
        .sweet-alert-text {
            color: #0f594e !important;
        }
        .btn-warning, .btn-info {
          color: #ffffff !important;
          background: #0f594e;
          border:1px solid #0f594e;
          box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 1px inset, rgba(49, 41, 30, 0.6) 0px 0px 8px;
          min-width: 120px;
        }
    }
}


.activity-template-aus-default {
    color: white;
    .bg-lifesherpa {
        background:#FF3739;
    }
    .filter-button-cancel {
        color: #CB251E;
        border: 2px solid #CB251E;
        background-color: #FFFFFF;
        &:hover {
            color: #FFFFFF;
            background-color: #CB251E;
        }
    }
    .filter-button-apply {
        background-color:#CB251E;
        color: #FFFFFF;
        border: 2px solid #CB251E;
        &:hover {
            background-color: #980000;
        }
    }
    .template-header {
        border-bottom: 1px solid white;
    }
    .primary-button {
        background-color:#CB251E;
        color: #FFFFFF;
        border: 2px solid #CB251E;
        &:hover {
            background-color: #980000;
        }
    }
    .secondary-button {
        color: #CB251E;
        border: 2px solid #CB251E;
        background-color: #FFFFFF;
        &:hover {
            color: #FFFFFF;
            background-color: #CB251E;
        }
    }
    .user-status-profile-background {
        color: white;
        background-color: rgba(49,49,50,1.0) !important;
        .text-secondary {
            color: white !important;
        }
        .border-bottom {
            border-bottom: 0px solid rgba(49,49,50,1.0) !important;
        }
    } 
    .user-status-list-form {
        color: white;
        background-color: rgba(49,49,50,1.0) !important;
        // border-bottom: 1px solid white !important;
        padding: 5px;
        margin: 10px 0px;
        box-shadow: 0 1px 5px 1px rgb(0, 0, 0) !important;
        .text-secondary {
            color: white !important;
        }
        .border-bottom {
            border-bottom: 0px solid rgba(49,49,50,1.0) !important;
        }
        .border-top {
            border-top: 0px solid rgba(49,49,50,1.0) !important;
        }
        .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
            background: #FF3739 linear-gradient(
           180deg, #FF3739, #FF3739) repeat-x;
        }
    }
    .checkin-container {
        min-height: 450px;
        background-color: rgba(49,49,50,1.0);
        z-index: 1;
    } 
    .MuiPaper-root, .template-header, .template-actions, .MuiDialogActions-root {
        background-color: rgba(49,49,50,1.0);
        color: white;
        .list-card {
            color: white;
        }
        .theame-color {
            background:#FF3739;
        }
        .form-submit-button {
            background-color:red;
            color: #FFFFFF;
        }
        a {
            color: #FF3739;
            &:hover {
                color: white;
            }
        }
        .countdown-edit-button {
            color: #FF3739;
            border: 2px solid #FF3739;
        }
        .form-cancel-button {
            color: #CB251E;
            border: 2px solid #CB251E;
            background-color: #FFFFFF;
            &:hover {
                color: #FFFFFF;
                background-color: #CB251E;
            }
        }
        .primary-button {
            background-color:#CB251E;
            color: #FFFFFF;
            border: 2px solid #CB251E;
            &:hover {
                background-color: #980000;
            }
        }
        .secondary-button {
            color: #CB251E;
            border: 2px solid #CB251E;
            background-color: #FFFFFF;
            &:hover {
                color: #FFFFFF;
                background-color: #CB251E;
            }
        }
        .backbuttonForPreviewLinkIframe {
            color: #CB251E;
            border: 1px solid #CB251E;
            background-color: #FFFFFF;
            .MuiSvgIcon-root,.linkButton{
                color: #CB251E;
            }
            &:hover {
                background-color: #CB251E;
                color:#FFFFFF;
                border: 1px solid #FFFFFF;
                .MuiSvgIcon-root,.linkButton{
                    color: #FFFFFF;
                }
            }
        }
        .listItemTitle{
            color: white;
        }
        .helpbutton {
            background: rgba(49,49,50,.95);
            color: red;
            border: 2px solid red;
        }
        .backbutton{
            background-color:red;
            color: #FFFFFF;
        }
        .backbutton:hover, .backbutton:focus{
            background-color:rgba(49,49,50,.95);
            color:  red;
            border: 2px solid red;
            .MuiSvgIcon-root{
                color:  red;
            }
        }
        .helpbutton:hover, .helpbutton:focus {
            background-color:red;
            color:  white;
            border: 0px solid rgba(49,49,50,.95);
        }
        .helpbutton:active, .backbutton:active{
            background-color:red;
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, .2), 0px 8px 10px 1px rgba(0, 0, 0, .14 ), 0px 3px 14px 2px rgba(0, 0, 0, .12);
        }
    }
    .template-content, .MuiDialogContent-root {
        background-color: rgba(49,49,50,1.0);
        .steptitle, .listItemTitle{
            color: white;
        }
        .text-secondary {
            color: white !important;
        }
        .text-dark {
            color: white !important;
        }
        .detailslistItem {
            color: white;
        }
        p {
            color: white;
        }

        .menuEditItem, .select-font,.MuiInputBase-root, .MuiFormLabel-root {
            color: white;
        }
        .MuiSwitch-colorPrimary.Mui-checked {
            color: red;
        }
        .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
            background-color:red;
        }
        .MuiSwitch-colorPrimary.Mui-unchecked {
            color: white;
        }
        .MuiSwitch-colorPrimary.Mui-unchecked + .MuiSwitch-track {
            background-color: white;
        }
       
        .chipStyle {
            color: red;
            border: 1px solid red;
            background: rgb(60,60,60);
        }
        .chipSelected {
            background: red;
            color: white;
            .MuiChip-avatar {
                color:#fff;
            }
        }
    }
    .text-secondary {
        color: white !important;
    }
    .text-dark {
        color: white !important;
    }
    .form-body-background {
        color: white;
        background-color: rgba(49,49,50,1.0);
        hr {
            background-color:white;
        }
        .bg-white {
          background-color: #28282b !important
        }
        .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
            background: #FF3739 linear-gradient(
        180deg, #FF3739, #FF3739) repeat-x;
        }
        .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
            background: #FF3739 linear-gradient(
        180deg, #FF3739, #FF3739) repeat-x;
        }
        .timer-value-color {
            color: white;
        }
    }
    hr {
        background-color:white;
    }
    .card, .card-body {
        background: rgb(60,60,60);
        color: white;
    }
    .rct-block-content{
        background: rgba(49,49,50,.95);
        .button-scratch{
            background: none;
            color: red;
        }
    }
    .applyFilter{
        background: red;
        color: rgba(49,49,50,.95);
        border: 2px solid rgba(49,49,50,.95);
    }
    .list-card {
        color: white;
        background: #4c4949;
        box-shadow: 0 1px 5px 1px rgb(0, 0, 0) !important;
      }
    .sweet-alert {
        color: white;
        background-color: #313133 !important;
        box-shadow: 0 1px 5px 1px rgb(0, 0, 0,) !important;
        border: 1px solid white;
        .sweet-alert-text {
            color: #ffffff !important;
        }
        .btn-warning, .btn-info {
          color: #fff !important;
          background: red;
          border:2px solid red;
          box-shadow:  0 1px 15px 1px rgba(219, 93, 9, 0.16);
          min-width: 120px;
        }
        .btn-default {
            min-width: 120px;
            min-height: 2.8rem;
            border: 2px solid;
        }
     }
}
.circular-label {
    width: 30px;
    height: 30px;
    border: 3px solid;
    border-radius: 15px;
    font-size: medium;
    text-align: center;
}
