.rct-session-wrapper-ls {
  .theme-background {
    background-color: $block-bg;
  }
  .horizontal-menu {
    .horizontal-navbar-link {
      border-bottom:2px solid #008C95 ;
      color: #008C95;
      .MuiSvgIcon-root {
         color:#008C95;
       }
   }
  }

  .user-profile-dropdown {
    color: rgb(80, 77, 77) !important;
  }
 .session-inner-wrapper {
    .session-body {
      .login-screen-button {
        background-color: #008C95;
        border:1px solid #008C95;
        color: #fff;
        &:hover , &:focus {
          background-color: #008C95;
        }
      }
    }
  }
  .resources-list-panel {
    .resources-category-buttons-grid {
      .resource-category-buttons {
        background-color: #008C95;
        color: #FFFFFF;
        border: 1px solid  #008C95;
      }
      .MuiButton-contained.Mui-disabled {
        background-color: #008C95;
        color: #FFFFFF;
        opacity: 0.7;
      }
    }  
  }
}
.session-wrapper-theme-support {
  background-color: var(--screenContentBackgroundColor);
  .theme-background {
    background-color: var(--screenContentBackgroundColor) !important;
    .MuiIconButton-root {
      color: var(--headerTitleColor);
    }
  }
  .rct-picker {
    .MuiInputAdornment-root {
      .MuiIconButton-root {
        color: var(--primaryButtonNormalStateColor);
      }
    }
  } 
  .role-warning-icon {
    color: var(--primaryButtonNormalStateColor);
  }
  .helpbutton {
    background: var(--primaryButtonNormalStateColor); //#00968F;
    color: var(--buttonTitleColor); //var(--primaryButtonNormalStateColor);
    border: 0px solid var(--primaryButtonNormalStateColor); //#00968F;
  }
  .scheduleDone {
    background: #00968F; //var(--primaryButtonNormalStateColor);
    color: #ffffff; //#00968F;
    border: 2px solid #ffffff; //#00968F;
  }
  .helpbutton:hover,
  .helpbutton:focus {
    background: var(--primaryButtonNormalStateColor); //#00968F;
    color: var(--buttonTitleColor); //var(--primaryButtonNormalStateColor);
    border: 0px solid var(--primaryButtonNormalStateColor); //#00968F;
    .MuiSvgIcon-root {
      color: var(--buttonTitleColor); //var(--primaryButtonNormalStateColor);
    }
  }
}

.session-wrapper-theme-support {
  @extend .session-wrapper-theme-support; 
  .rct-block {
    box-shadow:  0 1px 15px 1px rgba(69,65,78,0.16);
    .user-module-chart-feature {
      background-color:var(--screenContentBackgroundColor);
    }
  } 
  .rct-page {
    background: var(--screenContentBackgroundColor);
    .lifesherp-breadrumb-header {
      .breadcrumb, .MuiIconButton-label {
        color: var(--primaryButtonNormalStateColor); 
      }
    }
    .breadcrumb-item + .breadcrumb-item::before {
      color: var(--primaryButtonNormalStateColor); 
    }
   .footer {
     color: var(--headlineTextColor);
     background-color: var(--screenContentBackgroundColor);
   }
   .list-sherpa-activity  {
     .MuiSvgIcon-root {
       color: var(--headlineTextColor);
     }
     .people-left-right-icon {
       fill: var(--headlineTextColor);
     }
     .start-button-border-radius {
       border-radius: 50px;
     }
     .disabled-button {
      background-color: var(--buttonDisabledStateColor);
      color: var(--buttonTitleDisabledColor) !important;
      border: 2px solid var(--buttonDisabledStateColor);
     }
     .primary-button {
      background-color: var(--primaryButtonNormalStateColor);
      color: var(--buttonTitleColor);
      border: 2px solid var(--primaryButtonNormalStateColor);
    }
   }
  }  
  .chatsection {
    .header-icon-button {
      color: var(--headerTitleColor);
    } 
    .list-card {
      .chat-title {
        color: var(--headlineTextColor);
      }
    
    }
    .chat-footer {
      border-top: 1px solid var(--primaryButtonNormalStateColor);
      .primary-icon {
        color: var(--primaryButtonNormalStateColor);
      }
      .MuiIconButton-root {
        color: var(--primaryButtonNormalStateColor);
      }
      .Mui-disabled {
        color: var(--buttonDisabledStateColor)
      }
      .MuiIconButton-label {
        color: none;
      }
    }
  }
  .header-icon-button {
    color: var(--headerTitleColor);
  } 
  .app-header {
    .rct-header {
      background-color: var(--headerBackgroundColor) !important;
      .lifesherpa-logo {
        margin: 6px 25px;
        padding: 5px 10px;
        border-radius: 4px;
        background-color: var(--logoBackgroundColor);
      }
      .lifesherpa-theme-logo {
        margin: 4px 20px;
        padding: 5px;
        border-radius: 4px;
        .lifesherpa-poweredby-logo {
          padding-left: 0px;
        }
      }
      .auto-resize-img {
        max-width: 100%;
        max-height: 70px;
        height: auto;
        width: auto;
      }
      .header-theme-logo-background {
        background-color: var(--logoBackgroundColor);
      }
      .user-profile-dropdown {
        color: var(--headerTitleColor);
        .profile-dropdown-icon {
          .MuiSvgIcon-root {
            color: var(--headerTitleColor) !important;
          }
        }
        .dropdown-menu {
          .dropdown-list {
                  li {
                    background-color: $white !important;
                    a {
                      span {
                        color: var(--headlineTextColor);
                      }
                    }
                    &:hover {
                      a span {
                          color: var(--primaryButtonNormalStateColor) !important;
                      }
                      background-color: var(--screenContentBackgroundColor) !important;
                    }
                  }
          }
        }
      }
      .setting-icon {
        .edit-status-button {
          background-color: var(--tabBarButtonsColor);
          width: 36px;
          height: 36px;
          border-radius: 50%;
          .MuiSvgIcon-root, .MuiIconButton-label {
            color: var(--tabBarColor);
          }
        }
      }
    }
    .horizontal-menu {
      background-color: var(--tabBarColor);
      border-bottom: 1px solid var(--primaryButtonNormalStateColor);
      .MuiBadge-anchorOriginTopRightRectangle{
        top: -5px;
      }
       ul {
         li {
          a {
            color: var(--tabBarButtonsColor);
          }
           &:hover {
            a {
              color: var(--alternativeSecondaryButtonTitleColor);
              background:var(--alternativeSecondaryButtonNormalStateColor);
            }
           }
           .horizontal-navbar-link {
            border-bottom:2px solid var(--primaryButtonNormalStateColor);
            color: var(--primaryButtonNormalStateColor) !important;
            background: var(--tabBarButtonsColor);
            border-top: 1px solid var(--primaryButtonNormalStateColor);
            .MuiSvgIcon-root {
              color: var(--primaryButtonNormalStateColor);
            }
          }
         }
       }
    }
  }
  .login-screen {
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: var(--loginScreenBackgroundColor);
  }
  .auto-resize-img {
    max-width: 100%;
    max-height: 120px;
    height: auto;
    width: auto;
  }
  .auto-widget-resize-img {
    max-width: 100%;
    max-height: 70px;
    height: auto;
    width: auto;
  }
  .header-theme-logo-background {
    background-color: var(--logoBackgroundColor);
  }
  .lifesherpa-widget-theme-logo {
    margin: 4px 0px;
    padding: 5px;
    border-radius: 4px;
    .lifesherpa-poweredby-logo {
      padding-left: 0px;
    }
  }
  .session-logo {
    // background-color: var(--loginScreenBackgroundColor);
    width: fit-content;
    align-items: center;
    padding: 0px 5px;
    .lifesherpa-supported-logo {
      padding: 2px 8px;
      background-color: #f8f8f8;
      border-radius: 3px;
      display: inline-block;
      margin-left: 10px;
    }
  }
  .MuiBadge-colorPrimary {
    color: var(--badgeTextColor);
    background-color: var(--badgeColor);
    border: 1px solid var(--badgeBorderColor);
  }

  .notices-panel {
    background-color: var(--screenContentBackgroundColor);
    color: var(--headlineTextColor);
    .notice-history-alerts-panel-header {
      border-bottom: 1px solid var(--primaryButtonNormalStateColor);
      h3 {
        color: var(--headlineTextColor);
      }
    }
    .notice-view-page {
      .MuiButtonBase-root {
        .MuiSvgIcon-root {
          color: var(--primaryButtonNormalStateColor);
        }
      }
    }
    .user-later-avatar {
      background-color: var(--primaryButtonNormalStateColor);
      color: var(--buttonTitleColor);
    }
    .styleToListItem {
        background-color: #eceff3 !important;
        color: var(--primaryButtonNormalStateColor);
        .MuiIconButton-label {
          color: var(--primaryButtonNormalStateColor);
        }
    }
    .defaultStyleToListItem {
        color: var(--primaryButtonNormalStateColor);
        .MuiIconButton-label {
          color: var(--primaryButtonNormalStateColor);
        }
    }
    h3, h4 {
      color: var(--headlineTextColor);
    }
    .error-list-item {
      h3, h4 {
        color: var(--errorMessage);
      }
    }
    .activity-filter-icon {
      .MuiButtonBase-root {
        background: var(--primaryButtonNormalStateColor) !important;
        border: 1px solid var(--primaryButtonNormalStateColor);
        .filter-icon {
          background-color: var(--primaryButtonNormalStateColor);
          fill: var(--buttonTitleColor);
         }
        .MuiSvgIcon-root {
          color: var(--buttonTitleColor);
        }
      }
    } 
    .activity-filter-icon-applied {
      .MuiButtonBase-root {
        color: var(--secondaryButtonTitleColor);
        border: 1px solid var(--secondaryButtonTitleColor);
        background:var(--secondaryButtonNormalStateColor) !important;
        .filter-icon {
          background-color: var(--secondaryButtonNormalStateColor);
          fill: var(--secondaryButtonTitleColor);
         }
        .MuiSvgIcon-root {
          color: var(--secondaryButtonTitleColor);
        }
      }
    } 
    .activity-preview-form-container {
      .form-body-background {
          
          background-color:var(--screenContentBackgroundColor) ;
          hr {
              background-color: var(--headerBackgroundColor);
          }
          .form-label {
              background-color: var(--headerBackgroundColor);
              color: var(--headerTitleColor);
              padding: 5px 10px;
              width: 100%;
              margin-bottom: 0px;
              font-size: 17px;
          }
          .form-description {
              padding: 5px 10px;
          }
          .form-margin { 
              margin: 2px 10px;
          }
          .bg-white {
              background-color: var(--screenContentBackgroundColor) !important
            }
          .mb-2 {
              margin-bottom: 0px !important;
          }
          p {
              font-size: 16px;
          }
          .custom-control-label {
              width: 100%;
              font-size: 15px;
          }
          .form-input-field {
              margin: 8px 10px;
              font-size: 16px;
          }
          .preveiw-options {
              margin: 0px;
              padding: 3px 10px;
              border-bottom: 1px solid var(--headerBackgroundColor);
          }
          .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
              background: var(--checkboxTintColor) linear-gradient(
          180deg, var(--checkboxTintColor), var(--checkboxTintColor)) repeat-x;
          }
          .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
              background: var(--checkboxTintColor) linear-gradient(
          180deg, var(--checkboxTintColor), var(--checkboxTintColor)) repeat-x;
          }
          .rct-picker {
              .MuiInputBase-root {
                  color: var(--headlineTextColor);
              }
              .MuiFormLabel-root {
                  color: var(--headlineTextColor);
              }
          }
          .MuiIconButton-root {
            .MuiSvgIcon-root {
              color: var(--headlineTextColor);
            }
          }
      }
      .theme-header-background-style {
          background-color: var(--headerBackgroundColor);
          color: var(--headerTitleColor);
          .media-body {
              h4, h2 {
                  color: var(--headerTitleColor);
              }
          }
          .helpUrl-icon {
              color: var(--headerTitleColor) !important;
          }
      }
    }
  }
  header {
    height: 200px; //165px;
    background: none !important;
    justify-content: space-around;
    box-shadow: none;
   }
  .session-inner-wrapper {
    .loader-text {
      color: var(--primaryButtonNormalStateColor);
    }
    .session-body {
       color: var(--headlineTextColor) ;
      .login-screen-button {
        background-color: var(--primaryButtonNormalStateColor);
        border:1px solid var(--primaryButtonNormalStateColor);
        color: var(--buttonTitleColor);
        &:hover , &:focus {
          background-color: var(--primaryButtonNormalStateColor);
          opacity: 0.8;
        }
      }
      .base-font-color {
        color: var(--headlineTextColor) !important;
        font-size: 18px;
      }
    }
  }
  .bg-lifesherpa {
    background-color: var(--headerBackgroundColor);
    color: var(--headerTitleColor);
    .text-white {
      color: var(--headerTitleColor) !important;
    }
    .disableColorOnHeader {
      color: var(--disabledColorOnHeader);
    }
    .MuiSvgIcon-root {
      color: var(--headerTitleColor)
    }
  }
  .activity-completed {
    background-color: var(--progressBarColor);
  }
  .styleToCorseItem {
    background-color: #ffffff !important;
    color:var(--headlineTextColor);
    .MuiIconButton-label {
      color: var(--primaryButtonNormalStateColor);
    }
  }
  .defaultStyleToCorseItem {
    color: var(--headlineTextColor);
    .MuiIconButton-label {
      color: var(--primaryButtonNormalStateColor);
    }
  }
  .activity-icon-progressa {
    color: #FFFFFF;
    background-color: var(--headlineTextColor);
    border: 1px solid var(--headlineTextColor);
  }
  .activity-icon-progress {
    color: var(--alternativeSecondaryButtonTitleColor);
    background-color: var(--alternativeSecondaryButtonNormalStateColor);
    border: 1px solid var(--alternativeSecondaryButtonNormalStateColor);
  }
  .user-list-extra-info {
    color: var(--primaryButtonNormalStateColor);
    .reply-messages-icon {
      color: var(--primaryButtonNormalStateColor);
    }
    .unconfirmed-activity-icon {
      color: var(--primaryButtonNormalStateColor);
    }
    .unconfirmed-activities {
      color: var(--primaryButtonNormalStateColor);
    }
    .unread-messages {
      color: var(--primaryButtonNormalStateColor);
    }
  }
  .chat-sidebar {
    border: 1px solid var(--headerBackgroundColor);
    .chat-list {
      .activity-histroy-list {
        .user-list-item {
             color: var(--headlineTextColor); 
             box-shadow: none;
             h5 {
               color: var(--headlineTextColor);
             }
             span {
               color: var(--subtextColor);
             }
             background-color: var(--screenContentBackgroundColor);
             &.item-active {
              background-color: none; 
              border-top: 1px solid var(--screenContentBackgroundColor);
              box-shadow: none;
             }
           &:hover {
              &:not(.item-active) {
                background-color: var(--screenContentBackgroundColor);
                box-shadow: none;
              }
            }
          .media {
            .media-body {
              .media-body-text {
                color: var(--headlineTextColor);
                h4 {
                  color: var(--headlineTextColor);
                }
              }
              .media-body-sub-text {
                color: var(--subtextColor);
              }
            }
          }
          .achievementStatusCancelled {
            background-color: var(--achievementStatusCancelled);
          }
          .achievementStatusCompletedConfirmationNeeded {
            background-color: var(--achievementStatusCompletedConfirmationNeeded);
          }
          .achievementStatusCompletedNoConfirmationNeeded {
            background-color: var(--achievementStatusCompletedNoConfirmationNeeded);
          }
          .achievementStatusCompletedSkippedStepsNoConfirmationNeeded {
            background-color: var(--achievementStatusCompletedSkippedStepsNoConfirmationNeeded);
          }
          .achievementStatusConfirmed {
            background-color: var(--achievementStatusConfirmed);
          }
          .achievementStatusConfirmedNegative {
            background-color: var(--achievementStatusConfirmedNegative);
          }
          .achievementStatusConfirmedSkippedSteps {
            background-color: var(--achievementStatusConfirmedSkippedSteps);
          }
          .achievementStatusExpired {
            background-color: var(--achievementStatusExpired);
          }
          .achievementStatusInProgress {
            background-color: var(--achievementStatusInProgress);
          }
          .achievementStatusIncomplete {
            background-color: var(--achievementStatusIncomplete);
          }
          .achievementStatusNotStarted {
            background-color: var(--achievementStatusNotStarted);
          }
          .achievementStatusPaused {
            background-color: var(--achievementStatusPaused);
          }
          .achievementStatusNotFound {
            background-color: var(--disabledColorOnContent);
          }
         }
        .alternative-user-list-item {
          background-color: #FFFFFF;
        }
        .item-active {
          background-color: var(--headerBackgroundColor);
          .media {
            .media-body {
              .media-body-text {
                color: var(--headerTitleColor);
                h4 {
                  color: var(--headerTitleColor);
                }
              }
              .media-body-sub-text {
                color: var(--headerTitleColor);
              }
            }
          }
        }
      } 
    } 
  }
  .chat-main-body {
    .chat-head {
      .MuiIconButton-label {
        color: var(--headerTitleColor);
      }
      .text-muted {
        color: var(--headerTitleColor) !important
      }
      .MuiButton-root {
        background-color: var(--headerTitleColor);
        color: var(--headerBackgroundColor);
      }
      .border-bottom {
        border-bottom: 1px solid var(--headerBackgroundColor) !important;
      }
      .achievementStatusConfirmed {
        background-color: var(--achievementStatusConfirmed);
      }
    }
    .email-detail-page-warrper {
      .top-head {
        background-color: var(--headerBackgroundColor);
        .activity-status-details {
          color: var(--headerTitleColor);
        }
        h3 {
          color: var(--headerTitleColor);
        }
        .text-muted {
          color: var(--headlineTextColor) !important;
        }
        .satisfactory-no-button {
           background-color: var(--secondaryButtonNormalStateColor) ;
           border: 1px solid var(--secondaryButtonTitleColor) ;
           color: var(--secondaryButtonTitleColor) ;
           .MuiButton-root {
             color: var(--alternativeSecondaryButtonTitleColor);
           }
        }
        .satisfactory-yes-button {
          background-color: var(--primaryButtonNormalStateColor) ;
          border: 1px solid var(--buttonTitleColor) ;
          color: var(--buttonTitleColor) ;
          .MuiButton-root {
            color: var(--buttonTitleColor);
          }
        }
      }
      .list-unstyled {
        p {
          color: var(--headlineTextColor);
        }
      }
      .activity-history-steps-list {
        background-color: #FFFFFF;
      }
    }
    .achievementStatusCancelled {
      background-color: var(--achievementStatusCancelled);
    }
    .achievementStatusCompletedConfirmationNeeded {
      background-color: var(--achievementStatusCompletedConfirmationNeeded);
    }
    .achievementStatusCompletedNoConfirmationNeeded {
      background-color: var(--achievementStatusCompletedNoConfirmationNeeded);
    }
    .achievementStatusCompletedSkippedStepsNoConfirmationNeeded {
      background-color: var(--achievementStatusCompletedSkippedStepsNoConfirmationNeeded);
    }
    .achievementStatusConfirmed {
      background-color: var(--primaryButtonNormalStateColor); //var(--achievementStatusConfirmed);
    }
    .achievementStatusConfirmedNegative {
      background-color: var(--achievementStatusConfirmedNegative);
    }
    .achievementStatusConfirmedSkippedSteps {
      background-color: var(--achievementStatusConfirmedSkippedSteps);
    }
    .achievementStatusExpired {
      background-color: var(--achievementStatusExpired);
    }
    .achievementStatusInProgress {
      background-color: var(--achievementStatusInProgress);
    }
    .achievementStatusIncomplete {
      background-color: var(--achievementStatusIncomplete);
    }
    .achievementStatusNotStarted {
      background-color: var(--achievementStatusNotStarted);
    }
    .achievementStatusPaused {
      background-color: var(--achievementStatusPaused);
    }
    .achievementStatusNotFound {
      background-color: var(--disabledColorOnContent);
    }
  } 
  .No-activity-history-found {
    .MuiIconButton-label {
        color: var(--headerTitleColor);
    }
  }
  // .fixed-bottom {
  //   .add-activity-button {
  //     background-color: var(--primaryButtonNormalStateColor);
  //     color: var(--buttonTitleColor);
  //     .MuiSvgIcon-root {
  //       color: var(--buttonTitleColor);
  //     }
  //   }
  // }
  .resources-list-panel {
      color: var(--headlineTextColor);
      .base-font-color {
        color: var(--headlineTextColor) !important;
      }
      .resources-category-buttons-grid {
        .resource-category-buttons {
          color: var(--alternativeSecondaryButtonTitleColor);
          background-color:var(--alternativeSecondaryButtonNormalStateColor);
          border: 1px solid var(--alternativeSecondaryButtonNormalStateColor);
        }
        .resource-category-buttons-selected {
          color: var(--alternativeSecondaryButtonTitleColor);
          background-color: var(--alternativeSecondaryButtonSelectedStateColor);
          border: 1px solid var(--alternativeSecondaryButtonNormalStateColor);
        }
        .MuiButton-contained.Mui-disabled {
          color: var(--buttonTitleDisabledColor);
          box-shadow: none;
          background-color:var(--buttonDisabledStateColor);
          border: 1px solid var(--buttonDisabledStateColor);
        }
      }
      .download-label {
        color: var(--buttonTitleColor);
        background-color: var(--primaryButtonNormalStateColor);
        border: 1px solid var(--primaryButtonNormalStateColor);
        &:hover , &:focus {
          border: 1px solid var(--primaryButtonNormalStateColor);
          opacity: 0.7;
        }
      }
      .list-card-slider {
        background-color: #ffffff;
        color: var(--headlineTextColor);
       }  
       .text-secondary {
        color: var(--headlineTextColor) !important;
       } 
       .text-muted {
         color: var(--subtextColor) !important;
         a {
          color: var(--primaryButtonNormalStateColor) !important;
          text-decoration: underline;
         }
       }
      .slick-prev:before,
      .slick-next:before {
        color: var(--primaryButtonNormalStateColor); 
    }
    .resources-filters-header {
      .MuiButtonBase-root {
        background: var(--primaryButtonNormalStateColor) !important;
        border: 1px solid var(--primaryButtonNormalStateColor);
        .filter-icon {
          background-color: var(--primaryButtonNormalStateColor);
          fill: var(--buttonTitleColor);
         }
        .MuiSvgIcon-root {
          color: var(--buttonTitleColor);
        }
      }
    } 
    .resources-filters-header-filtered {
      .MuiButtonBase-root {
        color: var(--secondaryButtonTitleColor);
        border: 1px solid var(--secondaryButtonTitleColor);
        background:var(--secondaryButtonNormalStateColor) !important;
        .filter-icon {
          background-color: var(--secondaryButtonNormalStateColor);
          fill: var(--secondaryButtonTitleColor);
         }
        .MuiSvgIcon-root {
          color: var(--secondaryButtonTitleColor);
        }
      }
    } 
  }
  .actvity-routine-simple {
    .MuiSvgIcon-root {
      color:var(--primaryButtonNormalStateColor);
    }
  }
  .activities-inprogress-title {
    color: var(--headlineTextColor);
  }
  .activities-list-inprogress-title {
    background: var(--primaryButtonNormalStateColor);
    color: var(--buttonTitleColor);
  }
  .activities-list-item-border-inprogress {
    border: 2px solid var(--primaryButtonNormalStateColor);
  }
  .user-list-item {
    color: var(--headlineTextColor);
    h4 {
      color: var(--headlineTextColor);
    }
    .text-muted {
      color: var(--subtextColor) !important;
    }
    .moreButtonI {
      .MuiSvgIcon-root {
        color: var(--headlineTextColor);
      }
    }
    .client-list-activities-dropdown-icon {
      .MuiSvgIcon-root {
        color: var(--headlineTextColor);
      }
    }
  }
  .list-card {
    color: var(--subtextColor);
    background-color: #ffffff;/*var(--screenContentBackgroundColor);*/
    h4 {
      color: var(--headlineTextColor);
    }
    h1 {
      color: var(--headlineTextColor);
    }
    .more-icon-button {
      color: var(--headlineTextColor)
    }
    .user-more-icon-button {
        color: var(--headlineTextColor);
    }
    .headline-text-color {
      color: var(--headlineTextColor);
    }
    .avatar-icon {
      .MuiSvgIcon-root {
        color: var(--headlineTextColor);
        height: 60px;
        width: 60px;
      }
    }
    .activity-filter-icon {
      .MuiButtonBase-root {
        background: var(--primaryButtonNormalStateColor) !important;
        border: 1px solid var(--primaryButtonNormalStateColor);
        .filter-icon {
          background-color: var(--primaryButtonNormalStateColor);
          fill: var(--buttonTitleColor);
         }
        .MuiSvgIcon-root {
          color: var(--buttonTitleColor);
        }
      }
    } 
    .activity-filter-icon-applied {
      .MuiButtonBase-root {
        color: var(--secondaryButtonTitleColor);
        border: 1px solid var(--secondaryButtonTitleColor);
        background:var(--secondaryButtonNormalStateColor) !important;
        .filter-icon {
          background-color: var(--secondaryButtonNormalStateColor);
          fill: var(--secondaryButtonTitleColor);
         }
        .MuiSvgIcon-root {
          color: var(--secondaryButtonTitleColor);
        }
      }
    } 
    .org-label {
      background-color: var(--primaryButtonNormalStateColor);
      min-width: 100px;
      text-align: center;
      border-radius: 5px;
      color: var(--buttonTitleColor);
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .item-active {
		background-color: var(--headerBackgroundColor);
    color: #fff;
    h4 {
      color: #fff;
    }
    .avatar-icon {
      .MuiSvgIcon-root {
        color: #fff;
        height: 60px;
        width: 60px;
      }
    }
	}
  .ls-btn-tab-container{
    border: 2px solid var(--secondaryButtonTitleColor);
    background:var(--secondaryButtonNormalStateColor) !important;
    border-radius: 7px;
    .selected-btn{
      .MuiButton-root, .MuiIconButton-root {
        background-color:var(--secondaryButtonTitleColor);
        .MuiSvgIcon-root {
          color: var(--secondaryButtonNormalStateColor);
        }
        .people-left-right-icon{
          fill: var(--secondaryButtonNormalStateColor)
        }
      }
      .MuiIconButton-root {
        border-radius: 4px;
      }
      
    }
    .unselected-btn{
      .MuiButton-root, .MuiIconButton-root {
        background-color:var(--secondaryButtonNormalStateColor);
        .MuiSvgIcon-root {
          color: var(--secondaryButtonTitleColor);
        }
        .people-left-right-icon{
          fill: var(--secondaryButtonTitleColor);
        }
      }
      .MuiIconButton-root {
        border-radius: 4px;
      }
    }
  }
  .agenda-view-list-item {
    border-bottom: 2px solid var(--primaryButtonNormalStateColor);
    margin-bottom: 10px;
    border-radius: 5px;
  }
  .sweet-alert {
    color: var(--primaryButtonNormalStateColor);
    // background-color:var(--screenContentBackgroundColor) !important;
    box-shadow: 0 1px 5px 1px var(--primaryButtonNormalStateColor)  !important;
    border: 0px solid var(--alternativeSecondaryButtonNormalStateColor);
    .sweet-alert-text {
        color: var(--primaryButtonNormalStateColor) !important;
    }
    h2 {
        color: var(--headlineTextColor) !important;
    }
    .btn-warning, .btn-info {
        color:#fff !important;
        background: var(--alertButtonsColor);
        border:0px solid var(--alertButtonsColor);
        box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 1px inset, var(--alertButtonsColor) 0px 0px 8px;
        min-width: 120px;
        min-height: 2.8rem !important;
        padding: 0.6rem 2rem;
    }
    .btn-success, .btn-error {
      color:#fff !important;
      min-width: 120px;
    }
    .btn-default {
        border:1px solid var(--alertButtonsColor);
        min-width: 120px;
        min-height: 2.8rem !important;
        padding: 0.6rem 2rem;
        color: var(--alertButtonsColor);
        background-color: #fff;
    }
    .btn-warning:not(:disabled):not(.disabled):active {
        color:#fff !important;
        background-color: var(--alertButtonsColor);
    }
  }
  .full-screen-chat-wrapper {
    .chat-content {
      .chat-main-body {
        .chat-head {
          background-color: var(--headerBackgroundColor);
          color: var(--headerTitleColor);
          .MuiSvgIcon-root {
            color: var(--headerTitleColor);
          }
          .media {
            .media-body {
              .active-status-icon {
                color: var(--headerTitleColor);
              }
            }
            .user-later-avatar {
              color: var(--headerBackgroundColor);
              background-color: var(--headerTitleColor);
            }
          }
        }
        .chat-footer {
          .form-control{
            color: var(--headlineTextColor);
          }
          .form-control:focus {
            color: var(--headlineTextColor);
         }
        .MuiIconButton-root {
          color: var(--primaryButtonNormalStateColor);
        }
        .Mui-disabled {
          color: var(--buttonDisabledStateColor)
        }
        .MuiIconButton-label {
          color: none;
        }
        
        }
      }
    }
  }
  .workflow-history {
    .workflow-tab-bar {
      background-color: var(--tabBarButtonsColor);
      .selectedTab {
        background-color: var(--tabBarColor);
        color: var(--tabBarButtonsColor); 
      }
      .unSelectedTab {
        background-color: var(--tabBarButtonsColor);
        color: var(--tabBarColor);
      }
    }
  }
  .lifesherpa-communications {
    .chat-tab-bar {
      background-color: var(--tabBarColor);
      .selectedTab {
          background-color: var(--tabBarButtonsColor);
          color:  var(--tabBarColor)
      }
      .unSelectedTab {
        background-color: var(--tabBarColor);
        color: var(--tabBarButtonsColor)
      }
    }
    .video-groups-list-item {
      .group-icon {
        color:var(--primaryButtonNormalStateColor);
        border:3px solid var(--primaryButtonNormalStateColor)
      }
      .MuiSvgIcon-root {
        color: var(--primaryButtonNormalStateColor);
      }
    }
    .activity-filter-icon {
      .MuiButtonBase-root {
        background: var(--primaryButtonNormalStateColor) !important;
        border: 1px solid var(--primaryButtonNormalStateColor);
        .filter-icon {
          background-color: var(--primaryButtonNormalStateColor);
          fill: var(--buttonTitleColor);
         }
        .MuiSvgIcon-root {
          color: var(--buttonTitleColor);
        }
      }
    } 
    .activity-filter-icon-applied {
      .MuiButtonBase-root {
        color: var(--secondaryButtonTitleColor);
        border: 1px solid var(--secondaryButtonTitleColor);
        background:var(--secondaryButtonNormalStateColor) !important;
        .filter-icon {
          background-color: var(--secondaryButtonNormalStateColor);
          fill: var(--secondaryButtonTitleColor);
         }
        .MuiSvgIcon-root {
          color: var(--secondaryButtonTitleColor);
        }
      }
    } 
    .add-group-button {
      // background-color: var(--primaryButtonNormalStateColor);
      // color: var(--buttonTitleColor);
      background-color: #ffffff;
      color: #080808;
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, .2), 0px 8px 10px 1px rgba(0, 0, 0, 0.2), 0px 3px 14px 2px rgba(0, 0, 0, .2), 0px 8px 10px 1px rgba(0, 0, 0, .2);
      padding: 14px;
      .MuiSvgIcon-root {
        color: #080808; //var(--buttonTitleColor);
      }
    }
    .add-video-group-panel {
      .add-group-header {
        background-color: var(--headerBackgroundColor);
        color: var(--headerTitleColor);
        .MuiSvgIcon-root {
          color: var(--headerTitleColor);
        }
        .MuiIconButton-label {
          color: var(--headerTitleColor);
        }
        .save-button {
          color: var(--headerTitleColor);
        }
      }
      .list-card {
        color: var(--headlineTextColor);
      }
      .selected-input-container {
        border-bottom: 3px solid var(--primaryButtonNormalStateColor);
      }
     .selected-text {
        color: var(--primaryButtonNormalStateColor);
      }
      .primary-button {
        background-color: var(--primaryButtonNormalStateColor);
        color: var(--buttonTitleColor) !important;
        border: 2px solid var(--primaryButtonNormalStateColor);
      }
      .user-later-avatar {
        background-color: var(--primaryButtonNormalStateColor);
        color: var(--buttonTitleColor);
        .MuiSvgIcon-root {
          color: var(--buttonTitleColor);
        }
      }
    }
    .user-later-avatar {
      background-color: var(--primaryButtonNormalStateColor);
      color: var(--buttonTitleColor);
      .MuiSvgIcon-root {
        color: var(--buttonTitleColor);
      }
    }
    .join-group-call-button {
        background-color: var(--primaryButtonNormalStateColor);
        color: var(--buttonTitleColor) !important;
        border: 2px solid var(--primaryButtonNormalStateColor);
    }
    .localMessage {
      background-color: var(--primaryButtonNormalStateColor);
      color: var(--buttonTitleColor);
    }
    .userMessage {
      background-color: var(--secondaryButtonNormalStateColor);
      color: var(--secondaryButtonTitleColor);
      border:1px solid var(--secondaryButtonTitleColor);
    }
    .chat-footer {
      .MuiIconButton-root {
        color: var(--primaryButtonNormalStateColor);
      }
      .Mui-disabled {
        color: var(--buttonDisabledStateColor)
      }
      .MuiIconButton-label {
        color: none;
      }
    }
    .chat-panel-messges {
        .lifesherpa-chat-panel-header {
          .user-later-avatar {
            background-color: var(--headerTitleColor);
            color: var(--headerBackgroundColor);
          }
        }
    }
  }
  .localMessage {
    background-color: var(--primaryButtonNormalStateColor);
    color: var(--buttonTitleColor);
  }
  .userMessage {
    background-color: var(--secondaryButtonNormalStateColor);
    color: var(--secondaryButtonTitleColor);
    border:1px solid var(--secondaryButtonTitleColor);
  }
  .chat-msg-input-field {
    color: var(--headlineTextColor);
  }
  .chat-panel-messges {
    .scroll-to-bottom-icon {
      background-color: var(--alternativeSecondaryButtonNormalStateColor);
      color: var(--alternativeSecondaryButtonTitleColor);
    }
  } 
  .actvity-details-panel {
     .steps-list-item {
      .achievementStatusNotFound {
        background-color: var(--disabledColorOnContent);
      }
      .achievementStatusConfirmed {
        background-color: var(--achievementStatusConfirmed);
      }
      .achievementStatusCancelled {
        background-color: var(--achievementStatusCancelled);
      }
      h4 {
        color: var(--headlineTextColor);
      }
      .step-form-enable {
        color: var(--headlineTextColor);
      }
      .step-form-disable {
        color: var(--disabledColorOnContent);
      }
      .more-actions-button {
        color: var(--headlineTextColor);
      }
      .step-count-avatar {
        color: var(--headlineTextColor);
      }
      .step-status-avatar {
        .MuiSvgIcon-root {
           font-size: 2rem;
        }
      }
     }
     .rct-block-title {
       .MuiSvgIcon-colorPrimary {
         color: var(--headerTitleColor);
       }
     }
  }
  .heading-text {
    color: var(--headlineTextColor);
  }
  .lifesherpa-session-theme-support {
    .heading-text {
        color: var(--headlineTextColor);
    }
    .sub-text {
        color: var(--subtextColor);
    }
    .badge-expire {
      border-radius: 3px;
      background-color: var(--achievementStatusExpired);
      color: var(--buttonTitleColor);
    }
    .badge-primary {
      border-radius: 3px;
      background-color: var(--primaryButtonNormalStateColor);
      color: var(--buttonTitleColor);
    }
    .badge-inprogress {
      border-radius: 3px;
      background-color: var(--achievementStatusInProgress);
      color: var(--buttonTitleColor);
    }
    .color-primary {
      color: var(--primaryButtonNormalStateColor);
    }
    .theame-text{
      color: var(--progressBarColor);
    }
    .people-left-right-icon {
      fill: var(--primaryButtonNormalStateColor);
    }
    .text-underline {
      text-decoration: underline;
    }
    .header-text-color {
      color: var(--headerTitleColor);
    }
    .achievementStatusCancelled {
        background-color: var(--achievementStatusCancelled);
    }
    .achievementStatusCompletedConfirmationNeeded {
        background-color: var(--achievementStatusCompletedConfirmationNeeded);
    }
    .achievementStatusCompletedNoConfirmationNeeded {
        background-color: var(--achievementStatusCompletedNoConfirmationNeeded);
    }
    .achievementStatusCompletedSkippedStepsNoConfirmationNeeded {
        background-color: var(--achievementStatusCompletedSkippedStepsNoConfirmationNeeded);
    }
    .achievementStatusConfirmed {
        background-color: var(--achievementStatusConfirmed);
    }
    .achievementStatusConfirmedNegative {
        background-color: var(--achievementStatusConfirmedNegative);
    }
    .achievementStatusConfirmedSkippedSteps {
        background-color: var(--achievementStatusConfirmedSkippedSteps);
    }
    .achievementStatusExpired {
        background-color: var(--achievementStatusExpired);
    }
    .achievementStatusInProgress {
        background-color: var(--achievementStatusInProgress);
    }
    .achievementStatusIncomplete {
        background-color: var(--achievementStatusIncomplete);
    }
    .achievementStatusNotStarted {
        background-color: var(--achievementStatusNotStarted);
    }
    .achievementStatusPaused {
        background-color: var(--achievementStatusPaused);
    }
    .achievementStatusNotFound {
        background-color: var(--disabledColorOnContent);
    } 
    .satisfactory-no-button {
      background-color: var(--secondaryButtonNormalStateColor) ;
      border: 1px solid var(--secondaryButtonTitleColor) ;
      color: var(--secondaryButtonTitleColor) ;
      .MuiButton-root {
        color: var(--alternativeSecondaryButtonTitleColor);
      }
    }
    .satisfactory-yes-button {
      background-color: var(--primaryButtonNormalStateColor) ;
      border: 1px solid var(--buttonTitleColor) ;
      color: var(--buttonTitleColor) ;
      .MuiButton-root {
        color: var(--buttonTitleColor);
      }
    }
    .screen-content-background-color {
      background-color: var(--screenContentBackgroundColor);
    }
  }
  .achievement-history-list-container {
    .activities-history-full-screen {
      .activities-history-list-panel {
        border-right: 1px solid var(--headerBackgroundColor);
        .item-active {
          background-color: var(--headerBackgroundColor);
          .heading-text, label {
            color: var(--headerTitleColor);
          }
          .sub-text {
              color: var(--headerTitleColor);
          }
        }
      } 
    }
  }
  .dashboard-summary-panal-content {
    .MuiIconButton-root {
         color: var(--headlineTextColor);
    }
    .loading-dots { 
      &--dot {
        background-color: var(--primaryButtonNormalStateColor);
      }
  }
  .user-later-avatar {
    background-color: red;
    color: white;
    .MuiSvgIcon-root {
      color: white;
    }
  }
}

.session-wrapper-theme11 {
  background-color: #f8f8f8;
  .theme-background {
    background-color: #f8f8f8 !important;
  }
  .helpbutton {
    background: var(--primaryButtonNormalStateColor); //#00968F;
    color: #ffffff; //var(--primaryButtonNormalStateColor);
    border: 0px solid var(--primaryButtonNormalStateColor); //#00968F;
  }
  .scheduleDone {
    background: #00968F; //var(--primaryButtonNormalStateColor);
    color: #ffffff; //#00968F;
    border: 2px solid #ffffff; //#00968F;
  }
  .helpbutton:hover,
  .helpbutton:focus {
    background: #00968F;
    color: #ffffff;
    border: 2px solid #00968F;
    .MuiSvgIcon-root {
      color: #ffffff; //var(--primaryButtonNormalStateColor);
    }
  }
}

.rct-session-wrapper-theme11 {
  @extend .session-wrapper-theme11; 
  .rct-block {
    box-shadow:  0 1px 15px 1px rgba(69,65,78,0.16);
    .user-module-chart-feature {
      background-color: #f8f8f8;
    }
  } 
  .rct-page {
    background: #f8f8f8;
  }   
  .app-header {
    .lifesherpa-logo {
      margin: 10px;  
    }
    .rct-header {
      background-color: #D9D9D6 !important;
      .user-profile-dropdown {
        color: #000000;
        .profile-dropdown-icon {
          .MuiSvgIcon-root {
            color: #000000 !important;
          }
        }
        .dropdown-menu {
          .dropdown-list {
                &:hover {
                   a span {
                      color:#000000 !important;
                   }
                   background-color: $white !important;
                }
             }
         }
      }
      .setting-icon {
        .MuiIconButton-label {
          color: #000000;
        }
      }
    }
    .horizontal-menu {
      background-color: #000000;
       ul {
         li {
          a {
            color: #ffffff;
          }
           &:hover {
            a {
              color: #ffffff;
              background: #00968F;
            }
           }
           .horizontal-navbar-link {
            border-bottom:2px solid var(--primaryButtonNormalStateColor);
            color: var(--primaryButtonNormalStateColor) !important;
            background: #ffffff;
            border-top: 1px solid var(--primaryButtonNormalStateColor);
            .MuiSvgIcon-root {
              color: var(--primaryButtonNormalStateColor);
            }
          }
         }
       }
    }
  }
  .login-screen {
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #FFFFFF;
  }
  .session-logo {
    background-color: none;
  }
  .session-inner-wrapper {
    .session-body {
       color: var(--primaryButtonNormalStateColor) ;
      .login-screen-button {
        background-color: var(--primaryButtonNormalStateColor);
        border:1px solid var(--primaryButtonNormalStateColor);
        color: #ffffff;
        &:hover , &:focus {
          background-color: var(--primaryButtonNormalStateColor);
        }
      }
      .base-font-color {
        color: var(--primaryButtonNormalStateColor) !important;
        font-size: 18px;
      }
    }
  }
  .bg-lifesherpa {
    background-color: var(--primaryButtonNormalStateColor);
  }
  .styleToCorseItem {
    background-color: #d0d4d8 !important;
    color: var(--primaryButtonNormalStateColor);
    .MuiIconButton-label {
      color: var(--primaryButtonNormalStateColor);
    }
  }
  .defaultStyleToCorseItem {
    color: var(--primaryButtonNormalStateColor);
    .MuiIconButton-label {
      color: var(--primaryButtonNormalStateColor);
    }
  }
  .activity-icon-progressa {
    color: #ffffff;
    background-color: #00968F;
    border: 1px solid #00968F;
  }
  .activity-icon-progress {
    color: #fff;
    background-color: var(--primaryButtonNormalStateColor);
  }
  .resources-list-panel {
      color: var(--primaryButtonNormalStateColor);
      .base-font-color {
        color: var(--primaryButtonNormalStateColor) !important;
      }
      .MuiButtonBase-root {
        background: var(--primaryButtonNormalStateColor) !important;
      }
      .download-label {
        color: #FFFFFF;
        background-color: var(--primaryButtonNormalStateColor);
        border: 1px solid var(--primaryButtonNormalStateColor);
        &:hover , &:focus {
          background-color: #7a52a5;
        }
      }
      .resource-category-buttons {
        color: #FFFFFF;
        background-color: var(--primaryButtonNormalStateColor);
        border: 1px solid var(--primaryButtonNormalStateColor);
        &:hover , &:focus {
          background-color: #7a52a5;
        }
      }
      .slick-prev:before,
      .slick-next:before {
        color: var(--primaryButtonNormalStateColor); 
    }
    .MuiButtonBase-root:hover {
      background: #00968F !important;
    }
  }
  .actvity-routine-simple {
    .MuiSvgIcon-root {
      color:var(--primaryButtonNormalStateColor);
    }
  }
  .activities-inprogress-title {
    color: var(--primaryButtonNormalStateColor);
  }
  .activities-list-inprogress-title {
    background: var(--primaryButtonNormalStateColor);
    color: white;
  }
  .activities-list-item-border-inprogress {
    border: 2px solid var(--primaryButtonNormalStateColor);
  }
  .user-list-item {
    color: var(--primaryButtonNormalStateColor);
    h4 {
      color: var(--primaryButtonNormalStateColor);
    }
    .text-muted {
      color: var(--primaryButtonNormalStateColor) !important;
    }
    .unconfirmed-activities {
      color: var(--primaryButtonNormalStateColor);
    }
    .unread-messages {
      color: var(--primaryButtonNormalStateColor);
    }
  }
  .list-card {
    color: var(--primaryButtonNormalStateColor);
    h4 {
      color: var(--primaryButtonNormalStateColor);
    }
  }
  .sweet-alert {
    color: var(--primaryButtonNormalStateColor);
    background-color:var(--screenContentBackgroundColor) !important;
    box-shadow: 0 1px 5px 1px var(--primaryButtonNormalStateColor)  !important;
    border: 0px solid var(--alternativeSecondaryButtonNormalStateColor);
    .sweet-alert-text {
        color: var(--primaryButtonNormalStateColor) !important;
    }
    h2 {
        color: var(--headlineTextColor) !important;
    }
    .btn-warning, .btn-info {
        color:#fff !important;
        background: var(--alertButtonsColor);
        border:0px solid rgb(238, 162, 54);
        box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 1px inset, rgba(238, 162, 54,0.6) 0px 0px 8px;
        min-width: 120px;
        min-height: 2.8rem !important;
        padding: 0.6rem 2rem;
    }
    .btn-default {
        border:1px solid var(--alertButtonsColor);
        min-width: 120px;
        min-height: 2.8rem !important;
        padding: 0.6rem 2rem;
        color: var(--alertButtonsColor);
        background-color: #fff;
    }
    .btn-warning:not(:disabled):not(.disabled):active {
        color:#fff !important;
        background-color: var(--alertButtonsColor);
    }
}
}

.session-wrapper-theme2 {
  background-color: #f8f8f8;
  .theme-background {
    background-color: #F8F8F8 !important;
  }
  .helpbutton {
    background: #134266; //#00968F;
    color: #ffffff; //var(--primaryButtonNormalStateColor);
    border: 0px solid #134266; //#00968F;
  }
  .scheduleDone {
    background: #134266; //var(--primaryButtonNormalStateColor);
    color: #ffffff; //#00968F;
    border: 2px solid var(--primaryButtonNormalStateColor); //#00968F;
  }
  .helpbutton:hover,
  .helpbutton:focus {
    background: #fff;
    color: #134266;
    border: 2px solid #134266;
    .MuiSvgIcon-root {
      color: #134266; //var(--primaryButtonNormalStateColor);
    }
  }
  .user-profile-dropdown {
    color: rgb(80, 77, 77) ;
  }
}

.rct-session-wrapper-theme2 {
  @extend .session-wrapper-theme2; 
  .rct-block {
    box-shadow:  0 1px 15px 1px rgba(69,65,78,0.16);
    .user-module-chart-feature {
      background-color: #f8f8f8;
    }
  }  
  .rct-page {
    background: #EFEFF4;
  } 
  .app-header {
    .lifesherpa-logo {
      margin: 10px;  
    }
    .rct-header {
      background-color: #134266 !important;
      border-bottom: 1px solid  #FFFFFF;
      .user-profile-dropdown {
        color: #FFFFFF;
        .profile-dropdown-icon {
          .MuiSvgIcon-root {
            color: #FFFFFF !important;
          }
        }
        .dropdown-menu {
          .bg-lifesherpa {
            background: #8DC63F;
          }
          .dropdown-list {
                &:hover {
                   a span {
                      color:#134266 !important;
                   }
                   background-color: $white !important;
                }
             }
         }
      }
      .setting-icon {
        .MuiIconButton-label {
          color: #FFFFFF;
        }
      }
    }
    .horizontal-menu {
      background-color: #134266;
       ul {
         li {
          a {
            color: #ffffff;
          }
           &:hover {
            a {
              color: #ffffff;
              background: #8DC63F;
            }
           }
           .horizontal-navbar-link {
            border-bottom: 2px solid  #FFFFFF;
            color: #ffffff ;
            background-color: #8DC63F;
            .MuiSvgIcon-root {
              color: #ffffff;
            }
          }
         }
       }
    }
  }
  .login-screen {
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #F8F8F8;
  }
  .session-logo {
    background-color:  none;
  }
  .session-inner-wrapper {
    .session-body {
      color: #134266 ;
      box-shadow:  0 1px 15px 1px rgba(69,65,78,0.16);
      .base-font-color {
        color: #51727F !important;
        font-size: 18px;
      }
      .login-screen-button {
        color: #fff; 
        background-color: #134266;
        border:1px solid #134266;
        &:hover , &:focus {
          background-color: #103958;
        }
      }
    }
  }
  .bg-lifesherpa {
    background-color: #134266;
  }
  .styleToCorseItem {
    background-color: #d0d4d8 !important;
    color: #134266;
    .MuiIconButton-label {
      color: #134266;
    }
  }
  .defaultStyleToCorseItem {
    color: #134266;
    .MuiIconButton-label {
      color: #134266;
    }
  }
  .activity-icon-progressa {
    background-color:#134266;
    color: #ffffff;
  }

  .resources-list-panel {
      .base-font-color {
        color: #134266 !important;
      }
      .MuiButtonBase-root {
        background: #134266 !important;
      }
      .download-label {
        background-color: #134266;
        &:hover {
          background-color: #103958;
        }
      }
      .resource-category-buttons {
        color: #fff;
        background-color: #134266;
        border:2px solid #134266;
        &:hover {
          background-color: #103958;
        }
      }
      .slick-prev:before,
      .slick-next:before {
        color: #134266; 
    }
    .MuiButtonBase-root:hover {
      background: #134266 !important;
    }
  }
  .actvity-routine-simple {
    .MuiSvgIcon-root {
      color:#134266;
    }
  }
  .activities-inprogress-title {
    color: rgb(80, 77, 77);
  }
  .activities-list-inprogress-title {
    background: #134266;
    color: white;
  }
  .activities-list-item-border-inprogress {
    border: 2px solid #134266;
  }
  .user-list-item {
    color: #134266;
    h4 {
      color: #134266;
    }
    .text-muted {
      color: #134266 !important;
    }
    .unconfirmed-activities {
      color: #134266;
    }
    .unread-messages {
      color: #134266;
    }
  }
  .list-card {
    color: #134266;
    h4 {
      color: #134266;
    }
  }
  .sweet-alert {
    .sweet-alert-text {
      color: #134266 !important;
    }
    .btn-warning, .btn-info {
      color: #ffffff !important;
      background: #134266;
      border:1px solid #134266;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 1px inset, rgba(238, 162, 54,0.6) 0px 0px 8px;
      min-width: 120px;
    }
  }
}


.session-wrapper-theme5 {
  background-color: #f8f8f8;
  .theme-background {
    background-color: #f8f8f8 !important;
  }
  .helpbutton {
    background: #0f594e; //#00968F;
    color: #ffffff; //var(--primaryButtonNormalStateColor);
    border: 0px solid #0f594e; //#00968F;
  }
  .scheduleDone {
    background: #0f594e; //var(--primaryButtonNormalStateColor);
    color: #ffffff; //#00968F;
    border: 2px solid #0f594e; //#00968F;
  }
  .helpbutton:hover,
  .helpbutton:focus {
    background: #fff;
    color: #0f594e;
    border: 2px solid #0f594e;
    .MuiSvgIcon-root {
      color: #0f594e; //var(--primaryButtonNormalStateColor);
    }
  }
}

.rct-session-wrapper-theme5 {
  @extend .session-wrapper-theme5; 
  .rct-block {
    box-shadow:  0 1px 15px 1px rgba(69,65,78,0.16);
    .user-module-chart-feature {
      background-color: #f8f8f8;
    }
  }  
  .rct-page {
    background: #E0E0E0;
  } 
  .app-header {
    .lifesherpa-logo {
      margin: 10px;  
    }
    .rct-header {
      background-color: #0F594E !important;
      border-bottom: 1px solid  #FFFFFF;
      .user-profile-dropdown {
        color: #FFFFFF ;
        .profile-dropdown-icon {
          .MuiSvgIcon-root {
            color: #FFFFFF !important;
          }
        }
        .dropdown-menu {
          .bg-lifesherpa {
            background: #1b8272;
          }
          .dropdown-list {
                &:hover {
                   a span {
                      color:#0f594e !important;
                   }
                   background-color: $white !important;
                }
             }
         }
      }
      .setting-icon {
        .MuiIconButton-label {
          color: #FFFFFF;
        }
      }
    }
    .horizontal-menu {
      background-color: #0F594E;
       ul {
         li {
          a {
            color: #FFFFFF;
          }
           &:hover {
            a {
              color: #0f594e;
              background: #FFFFFF;
            }
           }
           .horizontal-navbar-link {
            border-bottom:2px solid #0f594e;
            color: #0f594e;
            background-color: #FFFFFF;
            .MuiSvgIcon-root {
              color: #0f594e;
            }
          }
         }
       }
    }
  }
  .login-screen {
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #0F594E;
  }
  .session-logo {
    background-color:  none;
  }
  .session-inner-wrapper {
    .session-body {
      color: #0f594e;
      .base-font-color {
        color: #0f594e !important;
        font-size: 18px;
      }
      .login-screen-button {
        color: #0F594E; 
        background: #ADCE65;
        border:1px solid #7A9B32;
        &:hover , &:focus {
          background-color: #7A9B32;
        }
      }
    }
  }
  .bg-lifesherpa {
    background-color: #0f594e;
  }
  .styleToCorseItem {
    background-color: #d0d4d8 !important;
    color: #0f594e;
    .MuiIconButton-label {
      color: #0f594e;
    }
  }
  .defaultStyleToCorseItem {
    color: #0f594e;
    .MuiIconButton-label {
      color: #0f594e;
    }
  }
  .activity-icon-progressa {
    background-color:#0f594e;
    color: #ffffff;
  }

  .resources-list-panel {
      .base-font-color {
        color: #0f594e !important;
      }
      .MuiButtonBase-root {
        background: #0f594e !important;
      }
      .download-label {
        background-color: #ADCE65;
        color:#0f594e;
        border: 1px solid #7A9B32;
        &:hover {
            background-color: #7A9B32;
        }
      }
      .resource-category-buttons {
        background-color: #ADCE65;
        color:#0f594e;
        border: 2px solid #7A9B32;
        &:hover {
            background-color: #7A9B32;
        }
      }
      .slick-prev:before,
      .slick-next:before {
        color: #134266; 
    }
    .MuiButtonBase-root:hover {
      background: #7A9B32 !important;
    }
  }
  .actvity-routine-simple {
    .MuiSvgIcon-root {
      color:#0F594E;
    }
  }
  .activities-inprogress-title {
    color:#0f594e;
  }
  .activities-list-inprogress-title {
    background: #0f594e;
    color: white;
  }
  .activities-list-item-border-inprogress {
    border: 2px solid #0f594e;
  }
  .user-list-item {
    color: #0f594e;
    h4 {
      color: #0f594e;
    }
    .text-muted {
      color: #0f594e !important;
    }
    .unconfirmed-activities {
      color: #0f594e;
    }
    .unread-messages {
      color: #0f594e;
    }
  }
  .list-card {
    color: #0f594e;
    h4 {
      color: #0f594e;
    }
  }
  .sweet-alert {
    .sweet-alert-text {
      color: #0f594e !important;
    }
    .btn-warning, .btn-info {
      color: #ffffff !important;
      background: #0f594e;
      border:1px solid #134266;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 1px inset, rgba(238, 162, 54,0.6) 0px 0px 8px;
      min-width: 120px;
    }
  }
}


.session-wrapper-aus {
  background-color: rgba(49, 49, 50, 1) !important;
  background-image: none;
  .helpbutton {
    background: #FF3739; //#00968F;
    color: #ffffff; //var(--primaryButtonNormalStateColor);
    border: 0px solid #134266; //#00968F;
  }
  .scheduleDone {
    background: red; //var(--primaryButtonNormalStateColor);
    color: #ffffff; //#00968F;
    border: 2px solid red; //#00968F;
  }
  .helpbutton:hover,
  .helpbutton:focus {
    background: #fff;
    color: red;
    border: 2px solid red;
    .MuiSvgIcon-root {
      color: red; //var(--primaryButtonNormalStateColor);
    }
  }
}

.rct-session-wrapper-aus {
  @extend .session-wrapper-aus;
  header {
    height: auto;
  }
  .theme-background {
    background-color: #424040 !important;
  }
  .resources-panel {
    .slick-slider {
      box-shadow: 0 1px 5px 1px black !important;
      margin: 20px 0px;
    }
  }
  .bg-lifesherpa {
    background:#FF3739;
  }
  .rct-page {
    background: black !important;
    .breadcrumb, .MuiIconButton-label {
      color: #fff; 
    }
  }
  .rct-block {
    box-shadow:  0 1px 15px 1px rgba(69,65,78,0.16);
    .user-module-chart-feature {
      background: #424040;
      .list-card {
        box-shadow: 0 1px 15px 1px rgb(0, 0, 0) !important;
      }
    }
    
  }  
  .app-header {
    .lifesherpa-logo {
      margin: 10px;  
    }
    .rct-header {
      background-color: #313132 !important;
      border-bottom: 1px solid #FFFFFF;
      .user-profile-dropdown {
        color: #FFFFFF;
        .profile-dropdown-icon {
          .MuiSvgIcon-root {
            color: #FFFFFF !important;
          }
        }
        .dropdown-menu {
           .dropdown-list {
                  a span {
                    color: #424040 !important;
                  }
                 li:hover {
                    a span {
                       color:#FF3739 !important;
                    }
                 }
              }
           }
      }
      .setting-icon {
        .MuiIconButton-label {
          color: #FFFFFF;
        }
      }
    }
    .horizontal-menu {
      background-color: #313132;
       ul {
         li {
          a {
            color: #FFFFFF;
          }
           &:hover {
            a {
              color: #FF3739;
              background: #FFFFFF;
            }
           }
           .horizontal-navbar-link {
            border-bottom:2px solid #FF3739;
            color: #FF3739;
            background-color: #FFFFFF;
            .MuiSvgIcon-root {
              color: #FF3739;
            }
          }
         }
       }
    }
  } 
  .login-screen {
    left: 0;
    position: fixed;
    right: 0;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: #313132;
  }
  .session-logo {
    background-color:  none;
  }
  .session-inner-wrapper {
    .session-body {
      color: white;
      background: rgb(49, 49, 51);
      box-shadow: 0 1px 15px 1px rgb(0, 0, 0) !important;
      .base-font-color {
        color: #ffffff !important;
        font-size: 18px;
      }
      .login-screen-button {
        color: #ffffff; 
        background-color: #CB251E ;
        border:2px solid #CB251E;
        &:hover , &:focus {
          background-color: #980000;
        }
      }
    }
  }
  .session-header {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .activity-icon-progressa {
    background-color:#FF3739;
    color: #fff;
  }
  .activity-icon-progress {
    color:black;
    background-color:  #fff;
  }
  .resources-list-panel {
      .base-font-color {
        color: white !important;
      }
      .list-card-slider {
        border:1px solid white;
        color: white;
        background-color: #424040;
        .text-secondary {
          color: white !important;
        }
        .text-muted {
          color: white !important;
        }
       }
      .MuiButtonBase-root {
        background: #FF3739 !important;
      }
      .download-label {
        background-color:#CB251E;
        color: #FFFFFF;
        border: 1px solid #CB251E;
        &:hover {
            background-color: #980000;
        }
      }
      .resource-category-buttons {
        background-color:#CB251E;
        color: #FFFFFF;
        border: 2px solid #CB251E;
        &:hover {
            background-color: #980000;
        }
      }
      .slick-prev:before,
      .slick-next:before {
        color: #FF3739; 
    }
    .MuiButtonBase-root:hover {
      background: #FF3739 !important;
    }
  }
  
  .actvity-routine-simple {
    .MuiSvgIcon-root {
      color:white;
    }
  }
  .defaultStyleToCorseItem {
    color: white;
    .MuiIconButton-label {
      color: white;
    }
  }
  .styleToCorseItem {
    background-color: #313133 !important;
    color: white;
    .MuiIconButton-label {
      color: white;
    }
  }
  .breadcumb-for-user-details {
    .breadcrumb {
      color: white;
    }
    .MuiSvgIcon-root {
      color: white;
    }
  }
  .activities-inprogress-title {
    color:red;
  }
  .footer {
    color: white !important;
    background-color: rgba(49, 49, 50, 1) !important;
    hr {
      border-top: 1px solid white;
    }
  }
  .activities-list-inprogress-title {
    background: #FF3739;
    color: white;
    border: 2px solid white;
  }
  .activities-list-item-border-inprogress {
    border: 2px solid white;
    color: white;
    background: #313132;
    box-shadow: 0 1px 5px 1px rgb(0, 0, 0) !important;
  }
  .activities-list-item-border{
    border: 0px solid white;
    color: white;
    background: #313132;
    box-shadow: 0 1px 5px 1px rgb(0, 0, 0) !important;
  }
  .no-data-found {
    color: white !important;
  }
  .user-list-item {
    color: white;
    background: rgb(49, 49, 51);
    box-shadow: 0 1px 5px 1px rgb(0, 0, 0) !important;
    .MuiSvgIcon-colorPrimary {
      color: white;
    }
    .MuiSvgIcon-root {
      color:white !important;
    }
    .text-muted {
      color: white !important;
    }
    .unconfirmed-activities {
      color: white;
    }
    .unread-messages {
      color: white;
    }
  }
  .list-card {
    color: white;
    background:#313132;
    .MuiSvgIcon-colorPrimary {
      color: white;
    }
    .MuiInputLabel-root {
      color: white;
    }
    .MuiInputBase-input {
      color: white;
    }
    .MuiSelect-icon {
      color: white;
    }
    .MuiInputBase-root {
      color: white;
    }
    .MuiInput-underline::after {
      border-bottom: 1px solid red;
    }
    .MuiInput-underline::before {
      border-bottom: 1px solid white;
    }
    box-shadow:  0 1px 15px 1px rgba(69,65,78,0.16);
  }
  .sweet-alert {
    color: white;
    background-color: #313133 !important;
    box-shadow: 0 1px 5px 1px rgb(0, 0, 0,) !important;
    border: 1px solid white;
    .btn-warning, .btn-info {
      color: #fff !important;
      background: red;
      border:2px solid red;
      box-shadow:  0 1px 15px 1px rgba(219, 93, 9, 0.16);
      min-width: 120px;
    }
  }
}
}
















