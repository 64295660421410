
.achievement-history-list-container {
    .rct-block-title {
        padding: 3px 2px 4px 2px;
        .menu-icon-button {
            padding: 0px;
            margin-left: 5px;
        }
        h2 {
            margin-bottom: 0px;
        }

    }
    .collapse {
        padding: 0px 0px;
    }
    .sub-header-list-card {
        margin-bottom: 0px;
        border-radius: 1px;
        background-color: #fafafa;
    }
    .activities-history-list-panel {
       
        .activity-history-list {
            padding: 5px 10px;
            position: relative;
            overflow-y: scroll;
            overflow-x: hidden;
            width: 100%;
            height: 560px;
            .list-card {
                .media-body {
                    line-height: 16px;
                    .sub-text {
                       font-size: 13px;
                    }
                }
                padding: 8px 10px;
            }
            .alternative-user-list-item {
                background-color: #fafafa;
            }
        }
    }
    .activities-history-details-panel {
        .activity-status-details {
            font-size: 13px;
        }
        .skipButton {
            margin-right: 10px;
        }
        .achievement-steps-list-panel {
            width: 100%;
            padding: 10px;
            .list-card {
                padding: 8px 10px;
            }
            .alternative-user-list-item {
                background-color: #fafafa;
            }
        }
        .satifactory-button {
            border-radius: 16px;
            min-width: 120px;
        }
        @media(max-width: 539px) {
            .skipButton {
                margin-bottom: 10px;
                margin-right: 0px;
            }
        }
    }
    .activity-time-duration {
        min-width: 90px;
        text-align: right;
    }

    .mr-10 {
        margin-right: 10px !important;
    }
    .activities-history-full-screen {
        .rct-block {
            margin-bottom: 0px;
            height: 675px;
            border-radius: 0px;
            .rct-block-title {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
            }
        }
        .activities-history-list-panel {
            width: 380px;
            .activity-history-list-full-screen {
                height: 580px;
            }
        }
    }
    
}


.legends-info-pupup {
    .list-unstyled  {
        .list-item {
            padding: 10px 13px;
            .MuiAvatar-root {
               margin-right: 12px;
               .MuiSvgIcon-root {
                font-size: 2rem;
               }
            }
            .legend-title {
                line-height: 18px;
            }
        }
    }
}
